<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="vehicles" dataKey="vehicleId" [resizableColumns]="true" styleClass="p-datatable-gridlines"
         [tableStyle]="{ 'min-width': '80rem' }"
         [paginator]="true" [rows]="10" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRecords"
         [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
         [rowsPerPageOptions]="[10, 20, 50]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Vehicles</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Vehicle" icon="pi pi-plus" (onClick)="addVehicle()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Plate Number</th>
            <th>Type</th>
            <th>Make</th>
            <th>Registration</th>
            <th>Payload</th>
            <th>Trip</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-vehicle>
        <tr>
            <td>{{ vehicle.plateNumber }}</td>
            <td>
                <div class="flex align-items-center justify-content-center" *ngIf="vehicle.type">
                    <img src="{{ vehicle.type.imageUrl}}"
                         alt="{{ vehicle.type.name }}"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                    {{ vehicle.config.name }} {{ vehicle.capacity.name }}
                </div>
            </td>
            <td>
                <div class="flex align-items-center justify-content-center" *ngIf="vehicle.make">
                    <img src="{{ vehicle.make.imageUrl}}"
                         alt="{{ vehicle.make.name }}"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                    {{ vehicle.make.name }}
                </div>
            </td>
            <td>{{ vehicle.registrationNumber }}<br>{{ vehicle.registrationDate | date:'dd/MM/yyyy' }}</td>
            <td><span *ngIf="vehicle.payloadTypes">{{ getPayloadTypesString(vehicle) }}</span></td>
            <td><span *ngIf="vehicle.tripTypes">{{ getTripTypesString(vehicle) }}</span></td>
            <td>
                <button pButton type="button" icon="pi pi-pencil" (click)="editVehicle(vehicle)"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deleteVehicle(vehicle)"
                        class="p-button-rounded p-button-text p-button-danger"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRecords}} vehicles.
        </div>
    </ng-template>
</p-table>

<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<p-dataView [value]="orderQueryList" [paginator]="true" [rows]="6" [lazy]="true" (onLazyLoad)="loadOrderList($event)"
    [totalRecords]="totalRecords">
    <ng-template pTemplate="header">Company Orders</ng-template>
    <ng-template let-orderQuery pTemplate="listItem">
        <div class="col-12 md:col-6 lg:col-6 xl:col-4">
            <div class="border-cyan-600 bg-cyan-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1"
                (click)="viewDetailPage(orderQuery)">
                <div class="col-12 flex justify-content-between align-items-center pr-4 pl-4">
                    <span class="text-3xl font-bold text-purple-500">{{orderQuery.queryId}}</span>
                    <p-image src="{{ orderQuery?.nominationPack?.receiver?.client?.logo != null ? orderQuery?.nominationPack?.receiver?.client?.logo
                            : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}" height="50"></p-image>
                </div>
                <div class="col-12 flex">
                    <div class="flex-grow-1 grid ml-1">
                        <div class="col-9"><span class="block text-base text-gray-600"><i
                                    class="fa-solid fa-building"></i>&nbsp;&nbsp;Client:
                                <b>{{orderQuery.nominationPack.receiver.client.name}}</b></span></div>
                        <div class="col-9"><span class="block text-base text-gray-600"><i
                                    class="fas fa-user-tie"></i>&nbsp;&nbsp;Contact:
                                <b>{{orderQuery.nominationPack.receiver.nominee.name}}</b></span></div>
                        <div class="col-9"><span class="block text-base text-gray-600"><i
                                    class="fas fa-user-tie"></i>&nbsp;&nbsp;Created By:
                                <b>{{orderQuery.customer.name}}</b></span></div>
                        <div class="col-6"><span class="block text-base text-gray-600"><i
                                    class="fa-solid fa-flag"></i>&nbsp;&nbsp;Pickup:
                                <b>{{orderQuery.pickupCity}}</b></span></div>
                        <div class="col-6"><span class="block text-base text-gray-600"><i
                                    class="fa-solid fa-flag-checkered"></i>&nbsp;&nbsp;Dropoff:
                                <b>{{orderQuery.dropoffCity}}</b></span></div>
                        <div class="col-6"><span class="block text-base text-gray-600"><i
                                    class="fa-solid fa-cubes-stacked"></i>&nbsp;&nbsp;Quantity:
                                <b>{{orderQuery.quantityRequired}}</b></span></div>
                        <div class="col-9"><span class="block text-base text-gray-600"><i
                                    class="fa-solid fa-calendar-check"></i>&nbsp;&nbsp;Move Date:
                                <b>{{showDate(orderQuery.requestedBookingTime)}}</b></span></div>
                        <div class="col-9"><span class="block text-base text-gray-600"><i
                                    class="fa-solid fa-truck-ramp-box"></i>&nbsp;&nbsp;VehicleModel Type:
                                <b>{{orderQuery.vehicleParaPack.vehicleConfig?.name}}
                                    {{orderQuery.vehicleParaPack.vehicleCapacity?.name}}</b></span></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dataView>

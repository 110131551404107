import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { shipmentRoutes } from './shipment/shipment.routes';
import { locationRoutes } from './location/location.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { accountsRoutes } from './accounts/accounts.routes';
import {contractRoutes} from "./contracts/contracts.routes";
import {companyRoutes} from "./company/company.routes";
import {customerRoutes} from "./customer/customer.routes";

const dashboardRoute: Route = { path: 'dashboard', component: DashboardComponent };
const loginRoute: Route = { path: 'login', component: LoginComponent };
export const routes: Routes = [dashboardRoute, loginRoute, ...shipmentRoutes,
  ...locationRoutes, ...accountsRoutes, ...contractRoutes, ...customerRoutes,
    ...companyRoutes];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeoAddress } from '../../models/common/geo-address.model';
import { GeoElement } from '../../models/common/geo-element.model';
import { LatLng } from '../../models/common/lat-lng';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {ActivatedRoute, Router} from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { SessionStorageService } from '../../services/session-storage.service';
import { CompanyService } from '../../company/company.service';
import { CustomerService } from '../../customer/customer.service';
import { GoogleMapsLibrariesLoaderService } from '../../services/google/google-libraries-loader.service';
import { Location } from '../location.model';
import { LocationService } from '../location.service';
import { AutoCompleteOnSelectEvent } from 'primeng/autocomplete';
import { Customer } from '../../customer/customer.model';
import { Company } from '../../company/company.model';

@Component({
	selector: 'app-create-location',
	templateUrl: './create-location.component.html',
	styleUrls: ['./create-location.component.css']
})
export class CreateLocationComponent implements OnInit {

	googleMap: any;
	createEditLocationForm: FormGroup<any>;
	createContactForm: FormGroup<any>;
	searchLocation: FormControl;
	searchDefaultContact: FormControl;
	companyDropdown: FormControl;
	locationTypeDropdown: FormControl;
	externalLocationReferenceInput: FormControl;
	nameInput: FormControl;


	searchPlaceResults: string[];
    locationId: number;
    isLoading: boolean = false;
	createEditLocation: Location = new Location();
	customerList: Customer[];
	showCustomerCreateDialog = false;
	searchContactResults: Customer[];
	newContact: Customer = new Customer();
	companyList: Company[];
	locationTypes = [{
		name: 'WAREHOUSE',
		value: 'WAREHOUSE'
	}, {
		name: 'OFFICE',
		value: 'OFFICE'
	}, {
		name: 'CUSTOMER',
		value: 'CUSTOMER'
	}]
	customerCreationInProgress: boolean = false;
	locationCreationInProgress: boolean = false;

	breadcrumbItems: MenuItem[];
	homeBreadcrumb: MenuItem;


	constructor(private sessionStorageService: SessionStorageService, private route: ActivatedRoute,
		private readonly googleMapsLibrariesLoaderService: GoogleMapsLibrariesLoaderService,
		private customerService: CustomerService, private router: Router,
		private messageService: MessageService, private companyService: CompanyService,
		private angularFireAuth: AngularFireAuth, private locationService: LocationService) {
	}

	ngOnInit(): void {
		this.createEditLocationForm = new FormGroup({
			searchLocation: new FormControl('', Validators.required),
			searchDefaultContact: new FormControl('', Validators.required),
			countryDropdown: new FormControl('', Validators.required),
			companyDropdown: new FormControl('', Validators.required),
			locationTypeDropdown: new FormControl('', Validators.required),
			externalLocationReferenceInput: new FormControl('', Validators.required),
			nameInput: new FormControl('', Validators.required),
		});
		this.createContactForm = new FormGroup({
			clientSelectDropdown: new FormControl('', Validators.required),
			contactNameInput: new FormControl('', Validators.required),
			contactPhoneInput: new FormControl('', Validators.required),
		});

        // Get location ID from route parameters
        const locationId = this.route.snapshot.paramMap.get('id');

        if (locationId) {
            this.locationId = Number(locationId);
            this.loadLocation(); // Load location if ID exists
        } else {
            this.initializeForNewLocation(); // Initialize for new location
        }

		this.breadcrumbItems = [{ label: 'Locations', routerLink: '/location' }, { label: locationId ? 'Update' : 'Create' }];
		this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
	}

    initializeForNewLocation() {
        // Initialize for a new location (if needed)
        this.createEditLocation = new Location();
        this.createEditLocation.country = this.sessionStorageService.getCountryList()?.find(country => country.countryCode == 'SA')!!;
    }

    loadLocation() {
        this.isLoading = true;
        this.locationService.getLocationById(this.locationId).subscribe(locationWrapper => {
            console.log(locationWrapper);
            this.createEditLocation = locationWrapper.data[0];
            this.updateFormValues();
            this.isLoading = false;
            if (this.createEditLocation.country)
                this.companyService.getCompaniesByCountry(this.createEditLocation.country.id).subscribe(companies => {
                    this.companyList = companies;
                });
        });
    }

    updateFormValues() {
        this.createEditLocationForm.patchValue({
            searchLocation: this.createEditLocation.name, // Assuming you want to display the name in the search field
            searchDefaultContact: this.createEditLocation.defaultContact,
            countryDropdown: this.createEditLocation.country,
            companyDropdown: this.createEditLocation.company,
            locationTypeDropdown: this.createEditLocation.locationType,
            externalLocationReferenceInput: this.createEditLocation.externalLocationReference,
            nameInput: this.createEditLocation.name
        });
    }

	ngAfterViewInit(): void {
		this.googleMapsLibrariesLoaderService.lazyLoadMap().subscribe(_ =>
			this.googleMap = new google.maps.Map(document.getElementById("map") as HTMLElement));
		if (!navigator.geolocation) {
			console.log('location is not supported');
		}
		navigator.geolocation.getCurrentPosition((position) => {
			//this.orderQuery.searchLocation = new LatLng(position.coords.latitude, position.coords.longitude);
		});
	}

	searchPlaces(event: { query: any; }) {
		const filtered: any[] = [];
		const displaySuggestions = (
			predictions: google.maps.places.QueryAutocompletePrediction[] | null,
			status: google.maps.places.PlacesServiceStatus
		) => {
			if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
				//alert(status);
				return;
			}
			predictions.forEach((prediction) => {
				filtered.push({ name: prediction.description, place_id: prediction.place_id });
			});
			this.searchPlaceResults = filtered;
		};
		// const autocompleteService = new google.maps.places.AutocompleteService();
		const autocompleteService = new google.maps.places.AutocompleteService();
		autocompleteService.getPlacePredictions({ input: event.query }, displaySuggestions);
	}

	placeSelect($event: any): void {
		const place_id = $event.value.place_id;
		const placesService = new google.maps.places.PlacesService(this.googleMap);
		this.createEditLocation.name = $event.value.name;

		placesService.getDetails({
			placeId: place_id,
			fields: ['name', 'formatted_address', 'place_id', 'geometry', 'adr_address', 'address_components', 'photos']
		}, (result, status) => {
			if (status != google.maps.places.PlacesServiceStatus.OK) {
				alert(status);
				return;
			} else {
				const lat = result?.geometry?.location?.lat().valueOf()!!;
				const lng = result?.geometry?.location?.lng().valueOf()!!;
				let geoAddress: GeoAddress = new GeoAddress();
				result?.address_components?.forEach(addressComponent => {
					let geoElement = new GeoElement();
					geoElement.name = addressComponent.short_name;
					geoElement.types = addressComponent.types;
					geoAddress.addressElements.push(geoElement);
				})
				this.createEditLocation.location = new LatLng(lat, lng);
				this.createEditLocation.address = geoAddress;
				this.createEditLocation.telephoneNumber = result?.formatted_phone_number ?? '';
				this.createEditLocation.buildingImage = result?.photos?.[0]?.getUrl() ?? '';
				this.createEditLocation.helperImage1 = result?.photos?.[1]?.getUrl() ?? '';
				this.createEditLocation.helperImage2 = result?.photos?.[2]?.getUrl() ?? '';
				this.createEditLocation.helperImage3 = result?.photos?.[3]?.getUrl() ?? '';
			}
		});
	}

	async searchContacts(event: { query: string; }): Promise<void> {
		if (event.query.length < 13) return;
		if ((event.query.startsWith('+966') || event.query.startsWith('+971')) && event.query.length != 13) return;
		let contactWrapped = await this.customerService.getCustomerByMobileNumber(event.query).toPromise();
		console.log(JSON.stringify(contactWrapped));
		if (contactWrapped?.success)
			this.searchContactResults = [contactWrapped.data];
		else {
			this.newContact = new Customer();
			this.messageService.add({
				severity: 'warn', summary: 'Contact not found',
				detail: 'The contact with the entered mobile number is not found in the system, please register the contact first!'
			});
			this.companyList = (await this.companyService.getCompaniesByCountry(2).toPromise())!!;
			this.newContact.phoneNumber = event.query;
			this.newContact.company = this.sessionStorageService.getCustomer()!!.company;
			this.showCustomerCreateDialog = true;
		}
	}

	contactSelect(event: AutoCompleteOnSelectEvent): void {
		this.createEditLocation.defaultContact = event.value;
	}

	async registerContact() {
		this.customerCreationInProgress = true;
		try {
			this.newContact.emailAddress = this.newContact.phoneNumber.replace('+', '') + '@abcxyz.com';
			this.newContact.countryId = 2;
			this.newContact.firebaseId = (await this.angularFireAuth.createUserWithEmailAndPassword(this.newContact.emailAddress, 'password1')).user!!.uid;
		}
		catch (e) {
			let errorMessage = null;
			if (typeof e === "string") {
				errorMessage = e.toUpperCase() // works, `e` narrowed to string
			} else if (e instanceof Error) {
				errorMessage = e.message // works, `e` narrowed to Error
			}
			this.customerCreationInProgress = true;
			this.messageService.add({
				severity: 'error', summary: 'Contact creation failed!',
				detail: 'Contact creation failed due to the following reason: ' + errorMessage
			});
			this.customerCreationInProgress = false;
		}
		this.customerService.createCustomer(this.newContact).then(createCustomerPromise => {
            createCustomerPromise.subscribe(createCustomerResponse => {
                if (createCustomerResponse.success) {
                    this.showCustomerCreateDialog = false;
                    this.createEditLocation.defaultContact = createCustomerResponse.data;
                    this.createEditLocationForm.controls['searchDefaultContact'].setValue(createCustomerResponse.data);
                    this.messageService.add({
                        severity: 'success', summary: 'Contact ' + createCustomerResponse.data.name + ' is created',
                        detail: 'Contact ' + createCustomerResponse.data.name + ' has been successfully created and added as the default contact'
                    });
                } else {
                    this.messageService.add({
                        severity: 'error', summary: 'Contact creation failed!',
                        detail: 'Your contact creation failed due to the following reason: ' + createCustomerResponse.message
                    });
                }
                this.customerCreationInProgress = false;
            });
		});
	}

	createUpdateLocation() {
		this.createEditLocation.managedBy = this.sessionStorageService.getCustomer()!!.company;
        if (this.createEditLocation.id) {
            this.locationService.updateLocation(this.createEditLocation).subscribe(createLocationResponse => {
                if (createLocationResponse.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Location ' + createLocationResponse.data.name + ' is updated',
                        detail: 'Location ' + createLocationResponse.data.name + ' has been successfully updated'
                    });
                    this.router.navigate(['/location']);
                }
            });
        } else {
            this.locationService.createLocation(this.createEditLocation).subscribe(createLocationResponse => {
                if (createLocationResponse.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Location ' + createLocationResponse.data.name + ' is created',
                        detail: 'Location ' + createLocationResponse.data.name + ' has been successfully created'
                    });
                    this.router.navigate(['/location']);
                }
            });
        }

	}

}

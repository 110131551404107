<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form class="overFlow" [formGroup]="createEditDriverForm" novalidate autocomplete="off" autocorrect="off"
          autocapitalize="off" spellcheck="off">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="fa-solid fa-user-tie mr-2" style="font-size: 1.5rem"></i>
                <div class="font-medium text-xl text-900">Create/Update Driver</div>
            </div><br>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-user"></i></span>
                        <input type="text" pInputText [(ngModel)]="driver.name" placeholder="Driver Name"
                               formControlName="nameInput" tooltipPosition="top" pTooltip="Enter the driver's full name" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-phone"></i></span>
                        <input type="text" pInputText [(ngModel)]="driver.phoneNumber" placeholder="Phone Number"
                               formControlName="phoneNumberInput" tooltipPosition="top" pTooltip="Enter the driver's phone number" />
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-envelope"></i></span>
                        <input type="text" pInputText [(ngModel)]="driver.emailAddress" placeholder="Email Address"
                               formControlName="emailAddressInput" tooltipPosition="top" pTooltip="Enter the driver's email address" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-flag"></i></span>
                        <p-dropdown id="nationality" [options]="countries" [(ngModel)]="selectedCountry"
                                    optionLabel="name" placeholder="Select Nationality" (onChange)="onCountryChange($event)"
                                    formControlName="nationalityDropdown" [style]="{'width': '100%'}" tooltipPosition="top" pTooltip="Select the driver's nationality">
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="{{ country.flag }}" alt="{{ country.name }} Flag" style="width: 18px; height: 18px; margin-right: 5px;">
                                    <div>{{ country.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-globe"></i></span>
                        <p-dropdown id="residingCountry" [options]="countries" [(ngModel)]="selectedResidingCountry"
                                    optionLabel="name" placeholder="Select Residing Country"
                                    (onChange)="onResidingCountryChange($event)"
                                    formControlName="residingCountryDropdown" [style]="{'width': '100%'}" tooltipPosition="top" pTooltip="Select the driver's residing country">
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="{{ country.flag }}" alt="{{ country.name }} Flag" style="width: 18px; height: 18px; margin-right: 5px;">
                                    <div>{{ country.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-location-dot"></i></span>
                        <input type="text" pInputText [(ngModel)]="driver.preferredAddress" placeholder="Preferred Address"
                               formControlName="preferredAddressInput" tooltipPosition="top" pTooltip="Enter the driver's preferred address" />
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-id-card"></i></span>
                        <input type="text" pInputText [(ngModel)]="driver.residenceNumber" placeholder="Iqama#"
                               formControlName="residenceNumberInput" tooltipPosition="top" pTooltip="Enter the driver's Iqama number" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-calendar-times"></i></span>
                        <p-calendar id="residenceExpiryDate" name="residenceExpiryDate" [(ngModel)]="selectedResidenceExpiryDate"
                                    [showIcon]="true" dateFormat="dd/mm/yy" inputId="residenceExpiryDate" [style]="{'width': '100%'}"
                                    formControlName="residenceExpiryDateInput" tooltipPosition="top" pTooltip="Select the driver's residence expiry date">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-id-card"></i></span>
                        <input type="text" pInputText [(ngModel)]="driver.licenseNumber" placeholder="License#"
                               formControlName="licenseNumberInput" tooltipPosition="top" pTooltip="Enter the driver's license number" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-calendar-times"></i></span>
                        <p-calendar id="licenseExpiryDate" name="licenseExpiryDate" [(ngModel)]="selectedLicenseExpiryDate"
                                    [showIcon]="true" dateFormat="dd/mm/yy" inputId="licenseExpiryDate" [style]="{'width': '100%'}"
                                    formControlName="licenseExpiryDateInput" tooltipPosition="top" pTooltip="Select the driver's license expiry date">
                        </p-calendar>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-id-card"></i></span> <!— File icon -->
                        <p-fileUpload name="residenceCardFile" accept=".pdf,.png,.jpg" mode="basic" [customUpload]="true"
                                      (onSelect)="onResidenceCardUpload($event)" chooseLabel="Upload Residence Card"></p-fileUpload>
                    </div>
                </div>
                <div class="flex col">
                    <img *ngIf="driver.residenceImage" [src]="driver.residenceImage" alt="Driver Residence" style="max-height: 45px;">
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-address-card"></i></span> <!— File icon -->
                        <p-fileUpload name="licenseCardFile" accept=".pdf,.png,.jpg" mode="basic" [customUpload]="true"
                                      (onSelect)="onLicenseCardUpload($event)" chooseLabel="Upload License Card"></p-fileUpload>
                    </div>
                </div>
                <div class="flex col">
                    <img *ngIf="driver.licenseImage" [src]="driver.licenseImage" alt="Driver License" style="max-height: 45px;">
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-truck"></i></span>
                        <p-dropdown id="currentVehicle" [options]="availableVehicles" [(ngModel)]="selectedVehicle"
                                    optionLabel="plateNumber" placeholder="Select Current Vehicle"
                                    (onChange)="onVehicleChange($event)" formControlName="currentVehicleDropdown"
                                    [style]="{'width': '100%'}" tooltipPosition="top" pTooltip="Select the driver's current vehicle">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row align-items-end">
                <div class="flex col">
                    <p-button label="{{ driver.id ? 'Update' : 'Create' }}" icon="fa-solid fa-plus" iconPos="left"
                              [loading]="driverCreationInProgress" class="m-1 px-1 py-1" (onClick)="saveDriver()"></p-button>
                    <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
                              class="m-1 px-1 py-1 p-button-help"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>

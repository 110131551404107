/* eslint-disable */
import { BaseProfile } from "../common/base-profile";
import { Company } from "./company.model";

export class Customer extends BaseProfile {
    corporateRole: string;
    customerStatus: string;
    address: string;
    company: Company;
    countryId: number;
}
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="receipts" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
         [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRequests" [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
         [rowsPerPageOptions]="[20, 50, 100]">
  <ng-template pTemplate="caption">
    <div class="col-12 flex overflow-hidden">
      <div class="flex-none flex align-items-center justify-content-center">
        <h2>Receipts</h2>
      </div>
      <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
      <div class="flex-none flex align-items-center justify-content-center">
        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel()" class="p-2" severity="help"></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Receipt#</th>
      <th>Receipt Date</th>
      <th>Payment Transaction#</th>
      <th>Amount</th>
      <th>Card/Bank Name</th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-receipt>
    <tr>
      <td>{{ receipt.odooReceiptNumber }}</td>
      <td>{{ showDate(receipt.odooReceiptDate) }}</td>
      <td>{{ receipt.paymentTransactionNumber }}</td>
      <td>{{ receipt.country.currencyCode + ' ' + receipt.amount }}</td>
      <td>{{ receipt.personalPaymentMethod.paymentCardName }}</td>
      <td>
        <p-button pTooltip="Download Receipt" icon="pi pi-download" (onClick)="downloadReceipt(receipt)" class="pr-1 pt-1 p-button-info"></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex align-items-center justify-content-between">
      In total there are {{totalRequests}} receipts.
    </div>
  </ng-template>
</p-table>

<div class="border-gray-600 bg-gray-50 border-round-lg p-4 ml-1 mt-3 mr-1">
  <div class="flex justify-content-between align-items-center">
    <h1 class="dashboard-title">Dashboard</h1>
    <p-calendar [(ngModel)]="selectedDateRange" [showIcon]="true" [showButtonBar]="true" [dateFormat]="'yy-mm-dd'"
      [rangeSeparator]="'-'" (onSelect)="onDateRangeChange()">
    </p-calendar>
  </div>

  <div class="grid pt-2">

    <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Total Shipments</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="pi pi-shopping-cart" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="totalShipments == null"></p-progressSpinner>
            <span  *ngIf="totalShipments != null" style="font-size: 2.7rem;">{{ totalShipments }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Total Shipment Amount</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="fa-solid fa-sack-dollar" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="totalShipmentAmount == null"></p-progressSpinner>
            <span *ngIf="totalShipmentAmount != null" style="font-size: 2.7rem;">{{ totalShipmentAmount | currency }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Invoiced Amount Pending</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="fa-solid fa-hourglass-half" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="invoicedAmountPending == null"></p-progressSpinner>
            <span *ngIf="invoicedAmountPending != null" style="font-size: 2.7rem;">{{ invoicedAmountPending | currency }}</span>
          </div>
        </div>
      </div>
    </div>



    <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Settlements Made</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="fa-solid fa-stamp" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="settlementsMade == null"></p-progressSpinner>
            <span *ngIf="settlementsMade != null" style="font-size: 2.7rem;">{{ settlementsMade | currency }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-12 md:col-9 lg:col-6 xl:col-4">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Top 5 Pickup Cities</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="fa-solid fa-plane-departure" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '150px', height: '150px'}" *ngIf="topPickupCitiesData == null"></p-progressSpinner>
            <p-chart *ngIf="topPickupCitiesData != null" type="bar" [data]="topPickupCitiesData" [options]="chartOptions" height="300px" [responsive]="true"></p-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-12 md:col-9 lg:col-6 xl:col-4">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Top 5 Dropoff Cities</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="fa-solid fa-plane-arrival" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '150px', height: '150px'}" *ngIf="topDropoffCitiesData == null"></p-progressSpinner>
            <p-chart *ngIf="topDropoffCitiesData != null" type="bar" [data]="topDropoffCitiesData" [options]="chartOptions" height="300px"></p-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-12 md:col-9 lg:col-6 xl:col-4">
      <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
        <div class="flex justify-content-between align-items-center pr-4 pl-4">
          <span style="font-size: 1rem;">Total Weight Transported/Day</span>
          <div class="bg-green-200 border-round-lg p-2 m-1">
            <div class="flex justify-content-between align-items-center">
              <i class="fa-solid fa-weight-scale" style="font-size: 1.2rem"></i>
            </div>
          </div>
        </div>
        <div class="col-12 flex">
          <div class="flex-grow-1 grid ml-1 pl-2">
            <p-progressSpinner [style]="{width: '150px', height: '150px'}" *ngIf="weightTransportedData == null"></p-progressSpinner>
            <p-chart *ngIf="weightTransportedData != null" type="line" [data]="weightTransportedData" [options]="chartOptions" height="300px"></p-chart>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '../../services/common/session-storage.service';
import { MenuItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { AccountsService } from 'src/app/accounts/accounts.service';
import { Receipt } from 'src/app/accounts/models/receipt.model';
import { TableLazyLoadEvent } from "primeng/table";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent {

  receipts: Receipt[];
  first = 0;
  rows = 20;
  totalRequests = 0;
  isLoading = true;

  breadcrumbItems: MenuItem[];
  homeBreadcrumb: MenuItem;

  constructor(private route: ActivatedRoute,
    private router: Router, private database: AngularFireDatabase,
    private sessionStorageService: SessionStorageService,
    private accountsService: AccountsService) {
    this.receipts = [];
  }

  loadData(event: TableLazyLoadEvent) {
    this.isLoading = true;
    this.first = event.first ?? 0;
    this.rows = event.rows ?? 20;
    let page = event.first ?? 0;
    page = page / 20;
    let pageSize = event.rows ?? 20;
    if (pageSize == 0)
      pageSize = 20;
    this.accountsService.findAllReceiptsByCompany(page, pageSize).subscribe(receiptsData => {
      console.log(receiptsData);
      this.receipts = receiptsData.items;
      this.totalRequests = receiptsData.count;
      this.isLoading = false;
    });
  }

  downloadReceipt(receipt: Receipt) {
    if (receipt.receiptFileUrl) {
      const a = document.createElement('a');
      a.href = receipt.receiptFileUrl;
      a.download = 'cargomate_receipt_' + receipt.odooReceiptNumber?.replace('/', '_') + '.pdf';
      a.target = '_blank';
      a.click();
    }
  }

  ngOnInit(): void {
    this.breadcrumbItems = [{ label: 'Accounts', routerLink: '/accounts' }, { label: 'Receipts' }];
    this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
  }

  showDate(longDate: number) {
		return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
	}

  exportToExcel() {
    // Create AOA
    const aoa: any[][] = [];
    aoa.push(['Receipt#', 'Receipt Date', 'Payment Transaction#', 'Amount', 'Card/Bank Name'],
      ...this.receipts.map((receipt) => [
        receipt.odooReceiptNumber,
        this.showDate(receipt.odooReceiptDate!),
        receipt.paymentTransactionNumber,
        receipt.country?.currencyCode + ' ' + receipt.amount,
        receipt.personalPaymentMethod?.paymentCardName
      ])
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Receipts');

    const fileName = 'cargomate_receipts_export_' + new Date().toISOString() + '.xlsx';
    XLSX.writeFile(workbook, fileName);

    // Create a blob from the workbook
    const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    );

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.target = '_blank';
    a.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(url);
  }
}

import { Component, OnInit } from '@angular/core';
import { VehicleService } from './vehicle.service';
import { Vehicle } from './vehicle.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import {TableLazyLoadEvent} from "primeng/table";

@Component({
    selector: 'app-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

    vehicles: Vehicle[] = [];
    isLoading: boolean = false;
    totalRecords: number = 0;
    first = 0;
    rows = 10;
    page = 0;
    pageSize = 10;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(
        private vehicleService: VehicleService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' }, { label: 'Vehicles' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    loadData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        this.page = event.first ?? 0;
        this.page = this.page / 10;
        this.pageSize = event.rows ?? 20;
        if (this.pageSize == 0)
            this.pageSize = 10;
        this.loadVehicles();
    }

    loadVehicles() {
        const companyId = this.sessionStorageService.getCompany()?.id;
        if (companyId) {
            this.vehicleService.findVehiclesByCompanyId(this.page, this.pageSize, companyId)
                .subscribe(response => {
                    console.log(response);
                    this.vehicles = response.items;
                    this.totalRecords = response.count;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Company ID not found.' });
        }
    }

    editVehicle(vehicle: Vehicle) {
        this.router.navigate(['/fleet/vehicle/edit', vehicle.vehicleId]);
    }

    deleteVehicle(vehicle: Vehicle) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this vehicle?',
            header: 'Confirm Delete',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.vehicleService.deleteVehicle(vehicle).subscribe(response => {
                    if (response) {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vehicle deleted successfully.' });
                        this.loadVehicles();
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete vehicle.' });
                    }
                });
            }
        });
    }

    addVehicle() {
        this.router.navigate(['/fleet/vehicle/edit']);
    }

    getPayloadTypesString(vehicle: Vehicle) {
        return vehicle.payloadTypes.map(payloadType => payloadType.name).join(', ');
    }

    getTripTypesString(vehicle: Vehicle) {
        return vehicle.tripTypes.map(tripType => tripType.name).join(', ');
    }
}

import { Routes } from '@angular/router';
import { FleetComponent } from "./fleet.component";
import { DriverComponent } from "./driver/driver.component";
import { VehicleComponent } from "./vehicle/vehicle.component";
import { EditDriverComponent } from "./driver/edit-driver/edit-driver.component";
import { EditVehicleComponent } from "./vehicle/edit-vehicle/edit-vehicle.component";

export const fleetRoutes: Routes = [
    {
        path: 'fleet',
        children: [
            {
                path: '',
                component: FleetComponent,
            },
            {
                path: 'drivers',
                component: DriverComponent,
            },
            {
                path: 'driver/edit/:id',
                component: EditDriverComponent,
            },
            {
                path: 'vehicles',
                component: VehicleComponent,
            },
            {
                path: 'vehicle/edit/:id',
                component: EditVehicleComponent,
            }
        ]
    },
];

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Invoice } from 'src/app/accounts/models/invoice.model';
import { AccountsService } from 'src/app/accounts/accounts.service';
import { formatDate } from '@angular/common';
import { Receipt } from 'src/app/accounts/models/receipt.model';
import { TableLazyLoadEvent } from "primeng/table";
import { InvoiceLine } from "../models/invoice-line.model";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent {

  invoices: Invoice[];
  first = 0;
  rows = 20;
  totalRequests = 0;
  isLoading = true;

  breadcrumbItems: MenuItem[];
  homeBreadcrumb: MenuItem;

  constructor(private route: ActivatedRoute,
              private router: Router, private accountsService: AccountsService) {
    this.invoices = [];
  }

  ngOnInit(): void {
    this.breadcrumbItems = [{ label: 'Accounts', routerLink: '/accounts' }, { label: 'Invoices' }];
    this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
  }

  loadData(event: TableLazyLoadEvent) {
    this.isLoading = true;
    this.first = event.first ?? 0;
    this.rows = event.rows ?? 20;
    let page = event.first ?? 0;
    page = page / 20;
    let pageSize = event.rows ?? 20;
    if (pageSize == 0)
      pageSize = 20;
    this.accountsService.findAllInvoicesByCompany(page, pageSize).subscribe(invoicesData => {
      console.log(this.invoices);
      this.invoices = invoicesData.items;
      this.totalRequests = invoicesData.count;
      this.isLoading = false;
    });
  }

  downloadInvoice(invoice: Invoice) {
    if (invoice.invoiceFileUrl) {
      const a = document.createElement('a');
      a.href = invoice.invoiceFileUrl;
      a.download = 'cargomate_invoice_' + invoice.odooInvoiceNumber?.replace('/', '_') + '.pdf';
      a.target = '_blank';
      a.click();
    }
  }

  showDate(longDate: number) {
    return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
  }

  showAllReceiptNumberWithComma(receipts: Receipt[]) {
    return receipts.map(receipt => receipt.odooReceiptNumber).join(', ');
  }

  showInvoiceStatus(invoice: Invoice) {
    const receiptAmount = invoice.receipts.map(receipt => receipt.amount)
      .reduce((subtotal, item) => (subtotal ?? 0) + (item ?? 0), 0);
    console.log(receiptAmount);
    return (receiptAmount ?? 0) >= (invoice.totalAmount ?? 0);
  }

  getUniqueOrderNumbers(invoice: Invoice) {
    return this.removeDuplicates(invoice.invoiceLines.map(invoiceLine => invoiceLine.queryId));
  }

  removeDuplicates<T>(array: T[]): T[] {
    return [...new Set(array)];
  }

  showOrderDetails(invoiceLine: InvoiceLine) {
    window.open(this.router.createUrlTree(
      ['/order/view', invoiceLine.queryId]).toString(), '_blank');
  }

  exportToExcel() {
    // Create AOA
    const aoa: any[][] = [];
    aoa.push(['Invoice#', 'Invoice Date', 'Amount Excluding VAT', 'Tax Amount', 'Total Amount', 'Paid With'],
      ...this.invoices.map((invoice) => [invoice.odooInvoiceNumber, this.showDate(invoice.invoiceCreationDate!),
        invoice.totalAmountExclTax, invoice.totalTax, invoice.totalAmount,
        invoice.receipts.map(receipt => receipt.odooReceiptNumber).join(', ')]))

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');

    const fileName = 'cargomate_invoices_export_' + new Date().toISOString() + '.xlsx';
    XLSX.writeFile(workbook, fileName);

    // Create a blob from the workbook
    const blob = new Blob([XLSX.write(workbook,
        { bookType: 'xlsx', type: 'array' })],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.target = '_blank';
    a.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(url);
  }
}

/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Location } from '../models/location/location.model';
import { SessionStorageService } from './common/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';

@Injectable()
export class LocationService {

    restApiUrl: string

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getLocationById(locationId: string) {
        return this.http.get<APIResponseWrapper<Location>>(`${this.restApiUrl}location-endpoint/locations?locationId=${locationId}`);
    }

    getLocationsByCompanyId(companyId: number) {
        return this.http.get<Location[]>(`${this.restApiUrl}location-endpoint/locations-by-company?companyId=${companyId}&cultureCode=en-US`);
    }
    

    createLocation(location: Location) {
        return this.http.post<APIResponseWrapper<Location>>(`${this.restApiUrl}location-endpoint/location`, location)
    }

    
    
}
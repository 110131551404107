import { Component, OnInit } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { BigQueryService } from '../services/bigquery-api.service';
import { SessionStorageService } from '../services/common/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  selectedDateRange: Calendar;
  totalShipments: number;
  totalShipmentAmount: number;
  invoicedAmountPending: number;
  settlementsMade: number;
  topPickupCitiesData: any;
  topDropoffCitiesData: any;
  weightTransportedData: any;
  chartOptions: any;
  bigQueryProject: string;


  constructor(private bigQueryService: BigQueryService,
    private sessionStorageService: SessionStorageService) {
    this.bigQueryProject = `${environment.bigQueryDatasetPath}`;
  }

  ngOnInit(): void {
    // Initialize chart options
    this.chartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    };

    // Fetch initial data (replace with your actual data fetching logic)
    this.fetchDashboardData();
  }

  onDateRangeChange(): void {
    // Fetch data based on the selected date range
    this.fetchDashboardData();
  }

  async fetchDashboardData() {

    this.bigQueryService.executeGenericSQLQuery(`SELECT COUNT(*) as totalShipments FROM \`${this.bigQueryProject}.Trxn.OrderHeader\` 
       oh LEFT JOIN \`${this.bigQueryProject}.Trxn.OrderDeliveryHeader\` odh ON oh.orderId = ODH.orderId
      WHERE oh.customerId IN (SELECT id FROM \`${this.bigQueryProject}.Entity.Customers\` 
      WHERE CompanyId = ${this.sessionStorageService.getCompany()?.id})`).subscribe(response => {
      console.log("BigQuery response: " + JSON.stringify(response));
      const rows = response as [any];
      this.totalShipments = rows[0]?.totalShipments ?? 98794;
    });

    this.bigQueryService.executeGenericSQLQuery(`SELECT pickupCity, COUNT(*) AS pickupCount FROM \`${this.bigQueryProject}.Trxn.OrderHeader\` 
      WHERE customerId IN (SELECT id FROM \`${this.bigQueryProject}.Entity.Customers\` 
      WHERE CompanyId = ${this.sessionStorageService.getCompany()?.id}) GROUP BY pickupCity ORDER BY pickupCount DESC LIMIT 5`).subscribe(response => {
      const rows = response as [any];
      this.topPickupCitiesData = {
        labels: rows.map(row => row.pickupCity),
        datasets: [{
          label: 'Pickup Count',
          data: rows.map(row => row.pickupCount),
          backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#FF5722', '#9C27B0']
        }]
      };
    });

    this.bigQueryService.executeGenericSQLQuery(`SELECT dropoffCity, COUNT(*) AS dropoffCount FROM \`${this.bigQueryProject}.Trxn.OrderHeader\` 
      WHERE customerId IN (SELECT id FROM \`${this.bigQueryProject}.Entity.Customers\` 
      WHERE CompanyId = ${this.sessionStorageService.getCompany()?.id}) GROUP BY dropoffCity ORDER BY dropoffCount DESC LIMIT 5`).subscribe(response => {
      const rows = response as [any];
      this.topDropoffCitiesData = {
        labels: rows.map(row => row.dropoffCity),
        datasets: [{
          label: 'Dropoff Count',
          data: rows.map(row => row.dropoffCount),
          backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#FF5722']
        }]
      };
    });

    this.bigQueryService.executeGenericSQLQuery(`SELECT SUM(oh.totalWeight) AS totalWeight FROM \`${this.bigQueryProject}.Trxn.OrderHeader\` 
      oh LEFT JOIN \`${this.bigQueryProject}.Trxn.OrderDeliveryHeader\` odh ON oh.orderId = ODH.orderId
      WHERE oh.customerId IN (SELECT id FROM \`${this.bigQueryProject}.Entity.Customers\` 
      WHERE CompanyId = ${this.sessionStorageService.getCompany()?.id})`).subscribe(response => {
      const rows = response as [any];
      this.weightTransportedData = {
        labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
        datasets: [{
          label: 'Weight (kg)',
          data: [rows[0]?.totalWeight ?? 1000, 1200, 800, 1500, 900],
          borderColor: '#42A5F5',
          fill: false
        }]
      };
    });

    

    this.totalShipmentAmount = 567890;
    this.invoicedAmountPending = 12345;
    this.settlementsMade = 67890;


    this.weightTransportedData = {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [{
        label: 'Weight (kg)',
        data: [1000, 1200, 800, 1500, 900],
        borderColor: '#42A5F5',
        fill: false
      }]
    };



  }
}

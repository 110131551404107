import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { orderRoutes } from './order/order.routes';
import { locationRoutes } from './location/location.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { accountsRoutes } from './accounts/accounts.routes';

const dashboardRoute: Route = { path: 'dashboard', component: DashboardComponent };
const loginRoute: Route = { path: 'login', component: LoginComponent };
export const routes: Routes = [dashboardRoute, loginRoute, ...orderRoutes, ...locationRoutes, ...accountsRoutes];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<p-toast></p-toast>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form class="overFlow" [formGroup]="createOrderForm" novalidate autocomplete="off" autocorrect="off"
        autocapitalize="off" spellcheck="off">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="pi pi-cart-plus mr-2" style="font-size: 1.5rem"></i>
                <div class="font-medium text-xl text-900">Create Easy Order</div>
            </div><br>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-a"></i></span>
                        <p-autoComplete
                            id="source-location"
                            [(ngModel)]="orderQuery.sourceLocation"
                            [suggestions]="filteredLocations"
                            (completeMethod)="filterLocations($event)"
                            field="name" [style]="{'width': '100%'}"
                            placeholder="Source Location"
                            pTooltip="Start typing in the Source Location to search"
                            tooltipPosition="top"
                            formControlName="sourceLocationDropdown"
                            (onSelect)="onLocationSelect('source')">
                            <ng-template let-location pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ location.code }}</div>
                                    <div>{{ location.name }}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-plus" (click)="addLocation()"></i></span>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-b"></i></span>
                        <p-autoComplete
                            id="destination-location"
                            [(ngModel)]="orderQuery.destinationLocation"
                            [suggestions]="filteredLocations"
                            (completeMethod)="filterLocations($event)"
                            field="name" [style]="{'width': '100%'}"
                            placeholder="Destination Location"
                            pTooltip="Start typing in the Destination Location to search"
                            tooltipPosition="top"
                            formControlName="destinationLocationDropdown"
                            (onSelect)="onLocationSelect('destination')">
                            <ng-template let-location pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ location.code }}</div>
                                    <div>{{ location.name }}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-plus" (click)="addLocation()"></i></span>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-plane-departure"></i></span>
                        <p-calendar [(ngModel)]="pickupDate" autoWidth="true" [style]="{'width': '100%'}"
                            [showTime]="true" placeholder="Pickup Date" formControlName="pickupDateSelect"></p-calendar>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-truck"></i></span>
                        <p-dropdown id="vehicle-type" [options]="vehicleTypes"
                            [(ngModel)]="orderQuery.vehicleParaPack.vehicleType" (ngModelChange)="vehicleTypeSelected()"
                            optionLabel="name" placeholder="VehicleModel Type" formControlName="vehicleTypeDropdown">
                            <ng-template let-vehicleType pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="{{ vehicleType.imageUrl }}" style="height: 28px" />
                                    <div>{{ vehicleType.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-sliders-v"></i></span>
                        <p-dropdown id="vehicle-config" [options]="vehicleConfigurations"
                            [(ngModel)]="orderQuery.vehicleParaPack.vehicleConfig" optionLabel="name"
                            placeholder="VehicleModel Configuration" [autoDisplayFirst]="false"
                            formControlName="vehicleConfigDropdown">
                            <ng-template let-vehicleConfig pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="{{ vehicleConfig.image }}" style="height: 28px" />
                                    <div>{{ vehicleConfig.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-th-large"></i></span>
                        <p-dropdown id="vehicle-capacity" [options]="vehicleCapacities"
                            [(ngModel)]="orderQuery.vehicleParaPack.vehicleCapacity" optionLabel="name"
                            placeholder="VehicleModel Capacity" [autoDisplayFirst]="false"
                            formControlName="vehicleCapacityDropdown">
                            <ng-template let-vehicleCapacity pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ vehicleCapacity.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-sort-numeric-up"></i></span>
                        <p-inputNumber [(ngModel)]="orderQuery.quantityRequired" placeholder="Number of Trucks"
                            [showButtons]="true" formControlName="quantityRequiredInput"></p-inputNumber>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-box"></i></span>
                        <p-dropdown id="cargo-type" [options]="payloadTypes"
                            [(ngModel)]="orderQuery.payloadItemList[0]!!.itemType" optionLabel="name"
                            placeholder="Cargo Type" formControlName="payloadTypeDropdown" [filter]="true"
                            filterBy="name" [showClear]="true">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <button pButton icon="pi pi-times" class="ml-3"></button>
                                </div>
                            </ng-template>
                            <ng-template let-payloadType pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="{{ payloadType.image }}" style="height: 28px" />
                                    <div>{{ payloadType.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-cubes-stacked"></i></span>
                        <p-inputNumber [(ngModel)]="orderQuery.payloadItemList[0]!!.itemQuantity!!.quantity"
                            suffix=" pallets" placeholder="Pallets count" [showButtons]="true"
                            formControlName="uomQuantityInput"></p-inputNumber>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-weight-scale"></i></span>
                        <p-inputNumber [(ngModel)]="totalWeight" suffix=" ton" placeholder="Total weight"
                            formControlName="itemWeightInput"></p-inputNumber>
                    </div>
                </div>
            </div>
            <!-- <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-money-bill"></i></span>
                        <p-inputNumber [(ngModel)]="orderQuery.sellingPrice" mode="currency" currency="SAR" locale="en-US"
                            placeholder="Price per truck" formControlName="pricePerTruckInput"></p-inputNumber>
                    </div>
                </div>
            </div> -->
            <div class="flex flex-column lg:flex-row align-items-end">
                <div class="flex col">
                    <p-button label="Create" icon="fa-solid fa-plus" iconPos="left"
                        [loading]="orderCreationInProgress" class="m-1 px-1 py-1" (onClick)="submitOrder()"></p-button>
                    <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left"
                        styleClass="p-button-secondary" class="m-1 px-1 py-1 p-button-help"></p-button>
                </div>
            </div>
        </div>

    </form>
</div>

<div id="map"></div>

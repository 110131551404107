import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderQueryView } from '../models/order-view/order-query-view';
import { OrderQueryService } from '../services/order-query.service';
import { SessionStorageService } from '../services/common/session-storage.service';
import { MenuItem } from 'primeng/api';
import { DataViewLazyLoadEvent } from 'primeng/dataview';

@Component({
	selector: 'app-list-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

	orderQueryList: OrderQueryView[] = [];
	orderQueryMap: Map<number, OrderQueryView[]> = new Map<number, OrderQueryView[]>();
	isLoading = true;
	totalRecords: number | undefined;
	currentPage: number = 0;
	breadcrumbItems: MenuItem[];
	homeBreadcrumb: MenuItem;

	constructor(private orderQueryService: OrderQueryService,
		private sessionStorageService: SessionStorageService,
		private router: Router) { }

	ngOnInit(): void {
		this.breadcrumbItems = [{ label: 'Orders', routerLink: '/list-order' }];
		this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
	}

	loadOrderList($event: DataViewLazyLoadEvent) {
		this.currentPage = Math.floor($event.first / $event.rows) + 1; 
		if (this.orderQueryMap.has(this.currentPage))
			this.orderQueryList = this.orderQueryMap.get(this.currentPage) || [];
		else {
			this.isLoading = true;
			this.orderQueryService.getOrdersByCustomerPaginated(this.currentPage, $event.rows, this.sessionStorageService.getCustomer()?.firebaseId!!)
			.subscribe((orders: OrderQueryView[]) => {
				this.orderQueryMap.set(this.currentPage, orders);
				this.orderQueryList = this.orderQueryMap.get(this.currentPage) || [];
				this.isLoading = false;
			});
		}
		this.orderQueryService.getOrdersByCustomerCount(this.sessionStorageService.getCustomer()?.firebaseId!!)
			.subscribe((ordersCount: number) => {
				console.log("Total Records: " + ordersCount);
				this.totalRecords = ordersCount;
			});
	}

	showDate(longDate: number) {
		return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
	}

	viewDetailPage(orderQuery: OrderQueryView): void {
		this.router.navigate(
			['/order/view', orderQuery.queryId]
		);
	}

}

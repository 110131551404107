/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Customer } from '../models/customer/customer.model';
import { SessionStorageService } from './common/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';

@Injectable()
export class CustomerService {

    restApiUrl: string

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getCustomerById(customerId: string) {
        return this.http.get<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer?customerId=${customerId}`);
    }

    getCustomerByMobileNumber(phoneNumber: string) {
        return this.http.get<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer?phoneNumber=${phoneNumber.replace('+','%2B')}`);
    }

    createCustomer(customer: Customer) {
        return this.http.post<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer`, customer)
    }

    
    
}
<p-toast />
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-6 pt-4 pl-4">
        <p class="text-4xl font-bold text-blue-800 p-0 m-0">Shipment# {{orderQuery.queryId}}</p>
        <p-tag icon="pi pi-user" value="{{orderQuery.orderStatus}}"></p-tag>
    </div>
    <div class="col-6 pt-4 pr-4">
        <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                class="fa-solid fa-sack-dollar"></i>&nbsp;&nbsp;{{orderQuery.countryOfOrder?.currencyCode}}
            {{orderQuery.sellingPrice! * orderQuery.quantityRequired!}}</p>
        <p class="text-l text-right text-red-200 p-0 m-0">{{orderQuery.quantityRequired}} x
            {{orderQuery.countryOfOrder?.currencyCode}} {{orderQuery.sellingPrice}}</p>
    </div>
</div>

<div *ngIf="!isLoading && (tripsCount == 0 || orderQuery?.quantityRequired! > tripsCount)" class="grid">
    <div class="col-12 p-4">
        <p-table [value]="orderJobList">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-12">
                        <p-progressBar *ngIf="!orderQueryStatus.isJobsGenerated" mode="indeterminate" class="m-1" [style]="{ height: '6px' }"></p-progressBar>
                    </div>
                    <div class="col-12 flex overflow-hidden">
                        <div class="flex-none flex align-items-center justify-content-center">
                            Finding & Invoking drivers nearby the Pickup Location...
                        </div>
                        <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                        <div class="flex-none flex align-items-center justify-content-center">
                            <p-button [outlined]="true" [loading]="!orderQueryStatus.isJobsGenerated" (onClick)="findNewDrivers()">
                                <img alt="logo" src="/icons/android-chrome-192x192.png" style="width: 1.5rem" />
                                <span class="ml-2 font-bold">Find Drivers from CargoMate</span>
                            </p-button>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center ml-2">
                            <p-button label="Assign Driver/Vehicle"  icon="fa-solid fa-people-arrows" (onClick)="generateDeliveries()"></p-button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Trip#</th>
                    <th>Driver</th>
                    <th>Vehicle</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orderJob>
                <tr>
                    <td>{{orderJob.deliveryText}}</td>
                    <td>
                        <p-chip *ngIf="orderJob.selectedDriver" [label]="orderJob.selectedDriver.legalName">
                            <img src="{{ orderJob.selectedDriver.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ orderJob.selectedDriver.legalName }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>
                    <td>
                        <p-chip *ngIf="orderJob.selectedVehicle" [label]="orderJob.selectedVehicle.plateNumber">
                            <img src="{{ orderJob.selectedVehicle.make?.logo ?? 'https://cdn-icons-png.flaticon.com/512/4047/4047296.png'}}"
                                 alt="{{ orderJob.selectedVehicle.plateNumber }}"
                                 style="width: 32px; height: 32px; border-radius: 30%;">
                        </p-chip>
                    </td>
                    <td>
                        <p-tag icon="pi pi-user" severity="success" value="{{orderJob.jobStatus}}"></p-tag>
                    </td>
                    <td>
                        <p-button label="Invoke" severity="warning" [loading]="invokeDriverInProgress" icon="fa-solid fa-phone-volume" (onClick)="invokeDriver(orderJob)"></p-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    {{jobsCount}} jobs are currently available.
                </div>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="!isLoading && tripsCount != 0" class="grid">
    <div class="col-12 p-4">
        <p-table [value]="orderDeliveryList">
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    Deliveries
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Trip#</th>
                    <th>Driver</th>
                    <th>Vehicle</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orderDelivery>
                <tr>
                    <td>{{orderDelivery.deliveryText}}</td>
                    <td>
                        <p-chip *ngIf="orderDelivery.deliveryDriver" [label]="orderDelivery.deliveryDriver.legalName">
                            <img src="{{ orderDelivery.deliveryDriver.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ orderDelivery.deliveryDriver.legalName }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>
                    <td>
                        <p-chip *ngIf="orderDelivery.deliveryVehicle" [label]="orderDelivery.deliveryVehicle.plateNumber">
                            <img src="{{ orderDelivery.deliveryVehicle.make?.logo ?? 'https://cdn-icons-png.flaticon.com/512/4047/4047296.png'}}"
                                 alt="{{ orderDelivery.deliveryVehicle.plateNumber }}"
                                 style="width: 32px; height: 32px; border-radius: 30%;">
                        </p-chip>
                    </td>
                    <td>
                        <p-tag icon="pi pi-user" severity="success" value="{{orderDelivery.jobStatus}}"></p-tag>
                    </td>
                    <td>
                        <p-button label="Track" severity="info" icon="pi pi-map" (onClick)="viewDeliveryPage(orderDelivery)"></p-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{tripsCount}} trips.
                </div>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-2">
    <div class="col-12 lg:col-6">
        <div class="grid bg-red-100 text-gray-900 border-round-md ml-3 mr-3">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-flag-checkered"></i>&nbsp;&nbsp;Sender Info:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ orderQuery.nominationPack.sender.client.logo != null ? orderQuery.nominationPack.sender.client.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                            height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <p-avatar image="{{ orderQuery.nominationPack.sender.nominee.imageUrl != null ? orderQuery.nominationPack.sender.nominee.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                orderQuery.nominationPack.sender.nominee.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Mobile#:&nbsp;&nbsp;<b>{{
                                orderQuery.nominationPack.sender.nominee.phoneNumber }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1"
                            *ngIf="orderQuery?.nominationPack?.sender?.nominee?.emailAddress?.search('@abcxyz.com') == 0">
                            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                orderQuery.nominationPack.sender.nominee.emailAddress }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Site:&nbsp;&nbsp;<b>{{
                                orderQuery.nominationPack.sender.client.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                                href="https://maps.google.com?q={{ orderQuery.nominationPack.sender.client.location.latitude }},{{ orderQuery.nominationPack.sender.client.location.longitude }}">{{pickupSubLocality}},
                                {{pickupLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="grid bg-green-100 text-gray-900 border-round-md ml-3 mr-3">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-flag"></i>&nbsp;&nbsp;Receiver Info:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ orderQuery.nominationPack.receiver.client.logo != null ? orderQuery.nominationPack.receiver.client.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                            height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <p-avatar image="{{ orderQuery.nominationPack.receiver.nominee.imageUrl != null ? orderQuery.nominationPack.receiver.nominee.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                orderQuery.nominationPack.receiver.nominee.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Mobile#:&nbsp;&nbsp;<b>{{
                                orderQuery.nominationPack.receiver.nominee.phoneNumber }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1"
                            *ngIf="orderQuery?.nominationPack?.sender?.nominee?.emailAddress?.search('@abcxyz.com') == 0">
                            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                orderQuery.nominationPack.receiver.nominee.emailAddress }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Site:&nbsp;&nbsp;<b>{{
                                orderQuery.nominationPack.receiver.client.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                                href="https://maps.google.com?q={{ orderQuery.nominationPack.receiver.client.location.latitude }},{{ orderQuery.nominationPack.receiver.client.location.longitude }}">{{dropoffSubLocality}},
                                {{dropoffLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-2">
    <div class="col-12 lg:col-6">
        <div class="grid bg-blue-100 text-gray-900 border-round-md ml-3 mr-3">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-truck"></i>&nbsp;&nbsp;Vehicle Type Requested:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ orderQuery.vehicleParaPack.vehicleConfig?.image != null ? orderQuery.vehicleParaPack.vehicleConfig?.image
                                : 'https://cdn-icons-png.flaticon.com/512/870/870181.png' }}"
                            height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3 pt-2">
                            <i class="fa-solid fa-wrench"></i>&nbsp;&nbsp;Type#:&nbsp;&nbsp;<b>{{
                                orderQuery.vehicleParaPack.vehicleType?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <i class="fa-solid fa-gear"></i>&nbsp;&nbsp;Config:&nbsp;&nbsp;<b>{{
                                orderQuery.vehicleParaPack.vehicleConfig?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-weight-hanging"></i>&nbsp;&nbsp;Capacity:&nbsp;&nbsp;<b>{{
                                orderQuery.vehicleParaPack.vehicleCapacity?.name }}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="grid bg-purple-100 text-gray-900 border-round-md ml-3 mr-3">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-truck-ramp-box"></i>&nbsp;&nbsp;Cargo Info:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ orderQuery!!.payloadItemList[0]!!.itemType?.image ? orderQuery!!.payloadItemList[0]!!.itemType?.image
                                : 'https://cdn-icons-png.flaticon.com/512/3285/3285047.png' }}"
                            height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3 pt-2">
                            <i class="fa-solid fa-wrench"></i>&nbsp;&nbsp;Type#:&nbsp;&nbsp;<b>{{
                                orderQuery!!.payloadItemList[0]!!.itemType?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <i class="fa-solid fa-gear"></i>&nbsp;&nbsp;Quantity:&nbsp;&nbsp;
                            <b>{{ orderQuery!!.payloadItemList[0]!!.itemQuantity?.quantity }} {{
                                orderQuery!!.payloadItemList[0]!!.itemQuantity?.uom?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-weight-hanging"></i>&nbsp;&nbsp;Weight:&nbsp;&nbsp;<b>{{
                                orderQuery!!.payloadItemList[0]!!.itemWeight }} {{
                                orderQuery!!.payloadItemList[0]!!.weightUnit?.shortName }}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="grid">
    <div class="col-12 p-4">
        <p-table [value]="orderFilesList">
            <ng-template pTemplate="caption">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        Order Files
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Add File" severity="help" icon="fa-solid fa-add" (onClick)="addNewFile()"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>File Name</th>
                    <th>File Type</th>
                    <th>Created At</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orderFile>
                <tr>
                    <td><a href="{{orderFile.fileUrl}}">{{orderFile.fileName}}</a></td>
                    <td>{{orderFile.fileType}}</td>
                    <td>{{showDate(orderFile.createdAt)}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{orderFilesCount}} files.
                </div>
            </ng-template>
        </p-table>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoAddress } from '../models/common/geo-address.model';
import { Location } from '../models/location/location.model';
import { SessionStorageService } from '../services/common/session-storage.service';
import { LocationService } from '../services/location.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  locations: Location[];
  locationsCount: any;

  breadcrumbItems: MenuItem[];
  homeBreadcrumb: MenuItem;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private database: AngularFireDatabase,
    private sessionStorageService: SessionStorageService,
    private locationService: LocationService) {
    this.locations = [];
    this.locationService.getLocationsByCompanyId(this.sessionStorageService.getCompany()?.id ?? 0).subscribe(locationsData => {
      this.locations = locationsData;
      this.locationsCount = this.locations.length;
    });
  }

  addLocation() {
    this.router.navigate(['/location/create']);
  }

  viewLocation(_t18: any) {
    throw new Error('Method not implemented.');
  }
  editLocation(_t18: any) {
    throw new Error('Method not implemented.');
  }
  deleteLocation(_t18: any) {
    throw new Error('Method not implemented.');
  }


  ngOnInit(): void {
    this.breadcrumbItems = [{ label: 'Locations', routerLink: '/location' }];
    this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
  }

  getLocationAddress(location: Location) {
    let geoAddress: GeoAddress = new GeoAddress().getGeoAddressFromObject(location.address);
    return geoAddress.getSubLocality() + ', ' + geoAddress.getLocality();
  }

}

<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<div class="grid mb-4">
    <div class="col-8 pt-4 pl-4">
        <p class="text-4xl font-bold text-blue-800 p-0 m-0 mb-1">Delivery# {{orderDelivery.deliveryText}}</p>
        <p-tag icon="pi pi-user" value="{{ orderJobStatus.jobStatus }}"></p-tag>
    </div>
    <div class="col-4 pt-4 pr-4">
        <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                class="fa-solid fa-sack-dollar"></i>&nbsp;&nbsp;{{orderQuery.countryOfOrder?.currencyCode}}
            {{orderQuery.sellingPrice}}</p>
        <p class="text-s text-right text-red-200 p-0 m-0">Transportation Charge:
            {{orderQuery.countryOfOrder?.currencyCode}} {{orderQuery.sellingPrice}}</p>
    </div>
</div>

<div class="grid mb-4">
    <div class="col-12 md:col-6 lg:col-4 pr-4 pl-4 card">
        <div class="card-container bg-red-100 border-round-md p-4">
            <div class="flex align-items-center font-bold text-red-700 text-2xl pb-4">
                Delivery Status:
            </div>
            <p-timeline [value]="timelineEvents">
                <ng-template pTemplate="marker" let-event>
                    <i [ngClass]="event.icon"></i>
                </ng-template>
                <ng-template pTemplate="content" let-event>
                    <small class="p-text-secondary">{{event.date}}</small>
                </ng-template>
                <ng-template pTemplate="opposite" let-event>
                    {{event.content}}
                </ng-template>
            </p-timeline>
        </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4 pr-4 pl-4">
        <div class="card">
            <div
                class="flex align-content-start align-items-center flex-wrap card-container bg-blue-100 border-round-md">
                <div class="flex align-items-center font-bold text-blue-700 text-xl m-2">
                    <p-avatar image="{{ orderDelivery.deliveryDriver.imageUrl != null ? orderDelivery.deliveryDriver.imageUrl 
                        : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2" size="large"
                        shape="circle" class="p-2"></p-avatar>Driver Details:
                </div>
                <div class="grid ml-2 mr-2 mb-2">
                    <div class="col-4">
                        Legal Name:
                    </div>
                    <div class="col-8">
                        <b>{{ orderDelivery.deliveryDriver.legalName }}</b>
                    </div>
                    <div class="col-4">
                        Phone Number:
                    </div>
                    <div class="col-8">
                        <b>{{ orderDelivery.deliveryDriver.phoneNumber }}</b>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryDriver.nationality != null">
                        Nationality:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryDriver.nationality != null">
                        <p-image src="{{ orderDelivery.deliveryDriver.nationality.flag }}"
                            alt="{{ orderDelivery.deliveryDriver.nationality.name }}" height="50"></p-image>
                        &nbsp;<b>{{ orderDelivery.deliveryDriver.nationality.name }}</b>
                    </div>
                    <div class="col-4">
                        Iqama#:
                    </div>
                    <div class="col-8">
                        <b>{{ orderDelivery.deliveryDriver.residenceNumber }}</b>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryDriver.residenceExpiryDate != null">
                        Iqama Expiry:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryDriver.residenceExpiryDate != null">
                        <b>{{ showDate(orderDelivery.deliveryDriver.residenceExpiryDate) }}</b>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryDriver.licenseExpiryDate != null">
                        License Expiry:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryDriver.licenseExpiryDate != null">
                        <b>{{ showDate(orderDelivery.deliveryDriver.licenseExpiryDate) }}</b>
                    </div>
                </div>
                <p-accordion class="ml-2 mr-2 mb-2 w-full">
                    <p-accordionTab header="Iqama Image" *ngIf="orderDelivery.deliveryDriver.residenceImage != null">
                        <p-image src="{{ orderDelivery.deliveryDriver.residenceImage }}"
                            alt="{{ orderDelivery.deliveryDriver.residenceNumber }}" height="150"></p-image>
                    </p-accordionTab>
                    <p-accordionTab header="License Image" *ngIf="orderDelivery.deliveryDriver.licenseImage != null">
                        <p-image src="{{ orderDelivery.deliveryDriver.licenseImage }}"
                            alt="{{ orderDelivery.deliveryDriver.licenseNumber }}" height="150"></p-image>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4 pr-4 pl-4">
        <div class="card">
            <div
                class="flex align-content-start align-items-center flex-wrap card-container bg-green-100 border-round-md">
                <div class="flex align-items-center font-bold text-green-700 text-xl ml-3">
                    <p-image src="{{ orderDelivery.deliveryVehicle.year.image != null ? orderDelivery.deliveryVehicle.year.image    
                        : 'https://cdn-icons-png.flaticon.com/512/870/870181.png'}}"
                        alt="{{ orderDelivery.deliveryVehicle.year.year }}" height="120"></p-image>&nbsp;&nbsp;Vehicle
                    Details:
                </div>
                <div class="grid ml-2 mr-2 mb-2">
                    <div class="col-4">
                        Plate Number:
                    </div>
                    <div class="col-8">
                        <b>{{ orderDelivery.deliveryVehicle.plateNumber }}</b>
                    </div>
                    <div class="col-4">
                        Vehicle Type:
                    </div>
                    <div class="col-8">
                        <b>{{ orderDelivery.deliveryVehicle.config.name }} {{
                            orderDelivery.deliveryVehicle.capacity.name }}</b>
                    </div>
                    <div class="col-12 flex align-content-center">
                        <p-image src="{{ orderDelivery.deliveryVehicle.config.image }}"
                            alt="{{ orderDelivery.deliveryVehicle.config.name }}" height="70"></p-image>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryVehicle.make != null">
                        Vehicle Make:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryVehicle.make  != null">
                        <b>{{ orderDelivery.deliveryVehicle.make.name }} {{
                            orderDelivery.deliveryVehicle.model.name }} {{ orderDelivery.deliveryVehicle.year.year
                            }}</b>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryVehicle.engineNumber  != null">
                        Engine#:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryVehicle.engineNumber  != null">
                        <b>{{ orderDelivery.deliveryVehicle.engineNumber }}</b>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryVehicle.registrationNumber  != null">
                        Registration#:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryVehicle.registrationNumber  != null">
                        <b>{{ orderDelivery.deliveryVehicle.registrationNumber }}</b>
                    </div>
                    <div class="col-4" *ngIf="orderDelivery.deliveryVehicle.registrationExpiry != null">
                        Registration Expiry:
                    </div>
                    <div class="col-8" *ngIf="orderDelivery.deliveryVehicle.registrationExpiry != null">
                        <b>{{ showDate(orderDelivery.deliveryVehicle.registrationExpiry) }}</b>
                    </div>
                </div>
                <p-accordion class="ml-2 mr-2 mb-2 w-full">
                    <p-accordionTab header="Registration Image"
                        *ngIf="orderDelivery.deliveryVehicle.registrationImage != null">
                        <p-image src="{{ orderDelivery.deliveryVehicle.registrationImage }}"
                            alt="{{ orderDelivery.deliveryVehicle.registrationImage }}" height="150"></p-image>
                    </p-accordionTab>
                    <p-accordionTab header="Vehicle Images"
                        *ngIf="orderDelivery.deliveryVehicle.vehicleImages.length != 0">
                        <p-galleria (value)="orderDelivery!!.deliveryVehicle.vehicleImages"
                            [containerStyle]="{ 'max-width': '640px' }" [numVisible]="4">
                            <ng-template pTemplate="item" let-item>
                                <img [src]="item" style="width: 100%;" />
                            </ng-template>
                            <ng-template pTemplate="thumbnail" let-item>
                                <div class="grid grid-nogutter justify-content-center">
                                    <img [src]="item" />
                                </div>
                            </ng-template>
                        </p-galleria>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</div>

<div class="grid">
    <div class="col-12 lg:col-6 pr-4 pl-4">
        <div class="card border-round-md">
            <google-map #map id="map-canvas" height="360px" width="100%" [zoom]="zoom" [center]="center"
                [options]="options">

                <map-marker #vehicleMarker [position]="vehicleLocation" [title]="'Vehicle Location'"
                    [icon]="vehicleMarkerImage" [options]="options" (mapClick)="openInfo(vehicleMarker)">
                    <map-info-window>{{ vehicleLocation.lat }}, {{ vehicleLocation.lng }}</map-info-window>
                </map-marker>

                <map-marker #pickupMarker [position]="pickupLocation" [title]="'Source Location'" [options]="options"
                    (mapClick)="openInfo(pickupMarker)">
                    <map-info-window>{{ pickupLocation.lat }}, {{ pickupLocation.lng }}</map-info-window>
                </map-marker>

                <map-marker #dropoffMarker [position]="dropoffLocation" [title]="'Destination Location'"
                    [options]="options" (mapClick)="openInfo(dropoffMarker)">
                    <map-info-window>{{ dropoffLocation.lat }}, {{ dropoffLocation.lng }}</map-info-window>
                </map-marker>

                <map-directions-renderer *ngIf="showRoute" [directions]="directionsResults">
                </map-directions-renderer>
            </google-map>
        </div>
    </div>
    <div class="col-12 lg:col-6 pr-4 pl-4">
        <p-table [value]="deliveryFilesList">
            <ng-template pTemplate="caption">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        Delivery Files
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Add File" severity="help" icon="fa-solid fa-add" (onClick)="addNewFile()"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>File Name</th>
                    <th>File Type</th>
                    <th>Created At</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-deliveryFile>
                <tr>
                    <td><a href="{{deliveryFile.fileUrl}}">{{deliveryFile.fileName}}</a></td>
                    <td>{{deliveryFile.fileType}}</td>
                    <td>{{showDate(deliveryFile.createdAt)}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{deliveryFilesCount}} files.
                </div>
            </ng-template>
        </p-table>
    </div>
</div>
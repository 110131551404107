/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { OrderQueryView } from "../models/order-view/order-query-view";
import { SessionStorageService } from './common/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class BigQueryService {


    constructor(private angularFireFunctions: AngularFireFunctions) { }

    executeGenericSQLQuery(sqlQuery: string) {
        /*return this.http.post<any>(`https://us-central1-prologix-test.cloudfunctions.net/executeBigQueryAPI`, {
            data: sqlQuery
        }, {
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        });*/
        return this.angularFireFunctions.httpsCallable('executeBigQueryAPI').call(null, sqlQuery);
    }

}
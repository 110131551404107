import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractsService } from '../contracts.service';
import { Contracts } from '../models/contracts.model';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {Company} from "../../company/company.model";
import {Customer} from "../../customer/customer.model";
import {CustomerService} from "../../customer/customer.service";
import {CompanyService} from "../../company/company.service";

@Component({
    selector: 'app-add-edit-contract',
    templateUrl: './add-edit-contract.component.html',
    styleUrls: ['./add-edit-contract.component.css']
})
export class AddEditContractComponent implements OnInit {

    createEditContractForm!: FormGroup;
    createEditContract: Contracts = new Contracts();
    contractCreationInProgress = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    parties: Company[] = []; // Replace with your actual data
    firstPartyReps: Customer[] = []; //
    secondPartyReps: Customer[] = []; // Replace with your actual data

    constructor(
        private fb: FormBuilder,
        private contractsService: ContractsService,
        private customerService: CustomerService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private location: Location
    ) { }

    ngOnInit(): void {
        // Load existing contract for editing
        const contractId = this.route.snapshot.paramMap.get('id');
        if (contractId) {
            this.loadContract(Number(contractId));
        }

        this.companyService.getCompaniesByCountry(2).subscribe(companies => {
            this.parties = companies;
        });

        this.breadcrumbItems = [
            { label: 'Contracts', routerLink: '/contracts' },
            { label: contractId ? 'Edit Contract' : 'Add Contract' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.createEditContractForm = this.fb.group({
            contractNumberInput: [''],
            firstPartyDropdown: [''],
            firstPartyRepDropdown: [''],
            secondPartyDropdown: [''],
            secondPartyRepDropdown: [''],
            statusInput: [''],
            startDateInput: [''],
            endDateInput: ['']
        });
    }

    loadContract(contractId: number) {
        this.contractsService.getContractById(contractId).subscribe(contract => {
            this.createEditContract = contract;
            this.updateFormValues();
        });
    }

    updateFormValues() {
        this.createEditContractForm.patchValue({
            contractNumberInput: this.createEditContract.contractNumber,
            firstPartyDropdown: this.createEditContract.firstParty,
            firstPartyRepDropdown: this.createEditContract.firstPartyRep,
            secondPartyDropdown: this.createEditContract.secondParty,
            secondPartyRepDropdown: this.createEditContract.secondPartyRep,
            statusInput: this.createEditContract.revisions
                .sort((a, b) => a.revisionNumber - b.revisionNumber)
                .map(value => value.status).pop() || '',
            startDateInput: this.createEditContract.createdDate,
            endDateInput: this.createEditContract.updatedDate
        });
    }

    createUpdateContract() {
        this.contractCreationInProgress = true;
        const contractData: Contracts = this.createEditContractForm.value;

        if (this.createEditContract.contractID) {
            // Update existing contract
            this.contractsService.updateContract(this.createEditContract.contractID, contractData)
                .subscribe(() => {
                    this.contractCreationInProgress = false;
                    // Handle success, e.g., show a message, navigate back
                    this.location.back();
                });
        } else {
            // Create new contract
            this.contractsService.createContract(contractData)
                .subscribe(() => {
                    this.contractCreationInProgress = false;
                    // Handle success, e.g., show a message, navigate back
                    this.location.back();
                });
        }
    }

    onContractFileUpload(event: any) {
        // Handle file upload logic here
        console.log(event.files);
    }

    onFirstPartyChanged() {
        this.customerService.getCustomersByCompany(this.createEditContract.firstParty.id).subscribe(value => {
           this.firstPartyReps = value;
        });
    }

    onSecondPartyChanged() {
        this.customerService.getCustomersByCompany(this.createEditContract.secondParty.id).subscribe(value => {
            this.secondPartyReps = value;
        });
    }
}

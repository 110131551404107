import { Component, OnInit } from '@angular/core';
import { ContractsService } from './contracts.service';
import { Contracts } from './models/contracts.model';
import { MenuItem } from 'primeng/api';
import {TableLazyLoadEvent} from "primeng/table";
import {SessionStorageService} from "../services/session-storage.service";
import {Router} from "@angular/router";
import {ContractStatus} from "./models/contract-status.model";

@Component({
    selector: 'app-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {

    contracts: Contracts[] = [];
    contractsCount = 0;

    first = 0;
    rows = 20;
    isLoading = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    constructor(private contractsService: ContractsService,
                private router: Router,
                private sessionStorageService: SessionStorageService) { }

    ngOnInit(): void {
        this.breadcrumbItems = [ { label: 'Contracts' } ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    loadContracts(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        let page = event.first ?? 0;
        page = page / 20;
        let pageSize = event.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.contractsService.getAllContracts(page, pageSize, this.sessionStorageService.getCompany()?.id ?? 0).subscribe(data => {
            console.log(data);
            this.contracts = data.items;
            this.contracts.forEach(value => {
                value.contractStatus = value.revisions
                    .sort((a, b) => a.revisionNumber - b.revisionNumber)
                    .map(value => value.status).pop() || ''
            })
            this.contractsCount = data.count;
            this.isLoading = false;
        });
    }

    // Add your addContract, viewContract, editContract, deleteContract methods here
    addContract() {
        // ... implementation for adding a new contract
    }

    viewContract(contract: Contracts) {
        this.router.navigate(['/contracts/view', contract.contractID]);
    }

    editContract(contract: Contracts) {
        this.router.navigate(['/contracts/edit', contract.contractID]);
        /*this.isLoading = true;
        this.contractsService.updateContract(contract.contractID, contract)
          .subscribe(() => {
            // Handle successful update (e.g., show a success message, reload contracts)
            console.log('Contract updated successfully!');
            this.contractsService.getAllContracts(this.first, this.rows).subscribe(data => {
              this.contracts = data.items;
              this.contractsCount = data.count;
              this.isLoading = false;
            }); // You might want to reload the contracts list
          });*/
    }

    deleteContract(contract: Contracts) {
        if (confirm(`Are you sure you want to delete contract ${contract.contractID}?`)) {
            this.isLoading = true;
            this.contractsService.deleteContract(contract.contractID)
                .subscribe(() => {
                    // Handle successful deletion (e.g., show a success message, reload contracts)
                    console.log('Contract deleted successfully!');
                    this.contractsService.getAllContracts(this.first, this.rows).subscribe(data => {
                        this.contracts = data.items;
                        this.contractsCount = data.count;
                        this.isLoading = false;
                    }); // You might want to reload the contracts list
                });
        }
    }

    getContractSeverity(status: string): string {
        switch (status) {
            case ContractStatus.DRAFT:
                return 'warning';
            case ContractStatus.ACTIVE:
                return 'success';
            case ContractStatus.TERMINATED:
                return 'danger';
            default:
                return 'info';
        }
    }
}

/* eslint-disable */
import { LatLng } from "../models/common/lat-lng";

export class Company {

    id: number;
    placeId: string;
    name: string;
    location: LatLng;
    phoneNumber: string;
    crNumber: number;
    taxRegNumber: string;
    logo: string;
    poBox: number;
    websiteURL: string;
    address: string;
    countryId: number;
    companyStatus: string;
    emailAddress: string;
    defaultContactId: number;
    transportationAgencyId: number;
    freightForwarderId: number;

}

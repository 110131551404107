/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { OrderQueryView } from "../models/order-view/order-query-view";
import { SessionStorageService } from './common/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';

@Injectable()
export class OrderJobService {

    transactionApiUrl: string;

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.transactionApiUrl = `${environment.reactiveApiUrl}`;
    }

    createOrder(orderQuery: OrderQueryView, cultureCode: string) {
        return this.http.post<APIResponseWrapper<OrderQueryView>>(`${this.transactionApiUrl}order`
        + "?cultureCode=" + cultureCode, JSON.stringify(orderQuery), {
            headers: {
                'Content-type': 'application/json'
            }
        });
    }

    getOrdersByCustomer(customerId: string) {
        return this.http.get<OrderQueryView[]>(`${this.transactionApiUrl}order/query/customer/${customerId}`
        + "?isPayloadItemsNeeded=" + "true"
        + "&isDeliveriesNeeded=" + "false"
        + "&isBiddingsNeeded=" + "false"
        + "&isNominationsNeeded=" + "true"
        + "&isFilesListNeeded=" + "false"
        + "&cultureCode=" + "en-US");
    }

    getJobByOrderAndJobNumber(queryId: string, jobId: string) {
        return this.http.get<OrderQueryView>(`${this.transactionApiUrl}jobs/order/${queryId}/job/${jobId}`
        + "?isDriverAndVehicleNeeded=" + "true"
        + "&isPayloadItemsNeeded=" + "true"
        + "&isnNominationsNeeded=" + "true"
        + "&isFilesNeeded=" + "true"
        + "&cultureCode=" + "en-US");
    }
    
}
import { Component, OnInit } from '@angular/core';
import { DriverService } from './driver.service';
import { Driver } from './driver.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import {TableLazyLoadEvent} from "primeng/table";

@Component({
    selector: 'app-driver',
    templateUrl: './driver.component.html',
    styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {

    drivers: Driver[] = [];
    isLoading: boolean = false;
    totalRecords: number = 0;
    first = 0;
    rows = 10;
    page = 0;
    pageSize = 10;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(
        private driverService: DriverService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' }, { label: 'Drivers' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    loadData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        this.page = event.first ?? 0;
        this.page = this.page / 10;
        this.pageSize = event.rows ?? 20;
        if (this.pageSize == 0)
            this.pageSize = 10;
        this.loadDrivers();
    }

    loadDrivers() {
        const transportationAgencyId = this.sessionStorageService.getCompany()?.transportationAgencyId;
        if (transportationAgencyId) {
            this.driverService.findDriversByTransportationAgencyId(this.page, this.pageSize, transportationAgencyId)
                .subscribe(response => {
                    console.log(response);
                    this.drivers = response.items;
                    this.totalRecords = response.count;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Transportation Agency ID not found.' });
        }
    }


    editDriver(driver: Driver) {
        this.router.navigate(['/fleet/driver/edit', driver.id]);
    }

    deleteDriver(driver: Driver) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this driver?',
            header: 'Confirm Delete',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.driverService.deleteDriver(driver).subscribe(response => {
                    if (response.success) {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'DriverModel deleted successfully.' });
                        this.loadDrivers();
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete driver.' });
                    }
                });
            }
        });
    }

    addDriver() {

    }
}

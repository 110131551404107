import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileProgressEvent, FileSelectEvent, FileUploadEvent, FileUploadHandlerEvent } from 'primeng/fileupload';
import { SessionStorageService } from 'src/app/services/common/session-storage.service';
import { DownloadUrlCallback, GoogleCloudStorageService } from 'src/app/services/google/google-cloud-storage.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

  filesSelected = false;
  uploadedFiles: any[] = [];
  fileTypes = [
    'Delivery Note', 'Bill of Laden', 'Miscellaneous'
  ];
  selectedFileType = '';
  uploadProgress: number = 0;
  callback: DownloadUrlCallback;



  constructor(private sessionStorageService: SessionStorageService,
    private messageService: MessageService, private config: DynamicDialogConfig,
    private googleCloudStorageService: GoogleCloudStorageService) { 
      this.callback = config.data.callback;
    }

  ngOnInit(): void { }

  onUpload(event: FileUploadHandlerEvent) {
    for (let i=0; i<event.files.length; i++) {
      let file = event.files.at(i)!!;
      let orderQuery = this.sessionStorageService.getCurrentOrder()!!;
      let orderDelivery = this.sessionStorageService.getCurrentDelivery();
      this.googleCloudStorageService.uploadFile(file, file.name, this.selectedFileType, null, this.uploadProgress, orderQuery.queryId, orderDelivery?.deliveryText, (jobAttachment) => {
        this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File ' + file.name + ' is uploaded successfully' });
        this.callback(jobAttachment);
      });
    }
  }

  onSelect(event: FileSelectEvent) {
    if (event.files.length >= 1)
      this.filesSelected = true;
    else this.filesSelected = false;
  }

  onProgress(event: FileProgressEvent) {
    event.progress = this.uploadProgress;
  }

}

import { AfterContentInit, Injectable, OnInit } from "@angular/core";
import { Vehicle } from "../fleet/vehicle/vehicle.model";
import { VehicleKeyPackModel } from "../fleet/vehicle/params/vehicle-key-pack";


@Injectable({
    providedIn: 'root'
})
export class VehicleKeyPack {
    componentVariable: VehicleKeyPackModel;

    constructor() {
        this.setInitialValues();
    }

    setInitialValues(): void {
        this.componentVariable = {
            vehicleId: 0,
            typeId: 0,
            configId: 0,
            capacityId: 0,
            isInsured: false,
            MAIN_SEPERATOR: '==',
            LIST_SEPERATOR: '&&',
            tripTypeIdList: [],
            payloadTypeIdList: [],
            fixedRate: false
        }
    }

    fromData(vehicle: Vehicle) {
        this.componentVariable.vehicleId = vehicle.vehicleId;
        this.componentVariable.typeId = vehicle.type?.id;
        this.componentVariable.configId = vehicle.config?.configId;
        this.componentVariable.capacityId = vehicle.capacity?.capacityId;
        this.componentVariable.isInsured = vehicle.isInsured != null && vehicle.isInsured;
        if (vehicle.currentDriver != null) {
            this.componentVariable.fixedRate = vehicle.currentDriver?.fixedRate != null
                && vehicle.currentDriver?.fixedRate;
        }
        for (let tripType of vehicle.tripTypes!!)
            if (!this.componentVariable.tripTypeIdList?.includes(tripType.id))
                this.componentVariable.tripTypeIdList?.push(tripType.id);
        for (let payloadType of vehicle.payloadTypes!!)
            if (!this.componentVariable.payloadTypeIdList?.includes(payloadType.typeId))
                this.componentVariable.payloadTypeIdList?.push(payloadType.typeId);
        return this;
    }

    toString() {
        let builder: string = '';
        builder = builder + this.componentVariable.vehicleId + this.componentVariable.MAIN_SEPERATOR;
        builder = builder + this.componentVariable.typeId + this.componentVariable.MAIN_SEPERATOR;
        builder = builder + this.componentVariable.configId + this.componentVariable.MAIN_SEPERATOR;
        builder = builder + this.componentVariable.capacityId + this.componentVariable.MAIN_SEPERATOR;
        builder = builder + (this.componentVariable.isInsured != null && this.componentVariable.isInsured)
            + this.componentVariable.MAIN_SEPERATOR;
        builder = builder + (this.componentVariable.fixedRate != null && this.componentVariable.fixedRate)
            + this.componentVariable.MAIN_SEPERATOR;

        for (let tripTypeId of this.componentVariable.tripTypeIdList!!) {
            builder = builder + tripTypeId + this.componentVariable.LIST_SEPERATOR;
        }
        if (this.componentVariable.tripTypeIdList?.length != 0) {
            builder = builder.substring(0, builder.length - 2);
        }
        builder = builder + this.componentVariable.MAIN_SEPERATOR;
        for (let payloadTypeId of this.componentVariable.payloadTypeIdList!!) {
            builder = builder + payloadTypeId + this.componentVariable.LIST_SEPERATOR;
        }
        if (this.componentVariable.payloadTypeIdList?.length != 0) {
            builder = builder.substring(0, builder.length - 2);
        }
        return builder;
    }
}



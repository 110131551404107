import { Routes } from '@angular/router';
import { AccountsComponent } from './accounts.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ReceiptComponent } from './receipt/receipt.component';

export const accountsRoutes: Routes = [
    {
        path: 'accounts',
        children: [
            {
                path: '',
                component: AccountsComponent,
            },
            {
                path: 'invoice',
                component: InvoiceComponent,
            },
            {
                path: 'receipt',
                component: ReceiptComponent
            },
        ]
    },
];
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { APIResponseWrapper } from "../../models/common/api-response-wrapper";
import { Vehicle } from "./vehicle.model";
import { APIListWrapper } from "../../models/common/api-list-wrapper.model";

@Injectable({
    providedIn: 'root'
})
export class VehicleService {

    private baseUrl = `${environment.restApiUrl}vehicle-endpoint`; // Adjust the base URL if needed

    constructor(private http: HttpClient) { }

    findAllGoodsInsuranceCompanies(countryId: number, cultureCode: string) { // Adjust return type if needed
        const params = { countryId: countryId.toString(), cultureCode };
        return this.http.get(`${this.baseUrl}/goods-insurance-companies`, { params });
    }

    findVehicleByIds(cultureCode: string, vehicleIds: number[])  {
        const params = { cultureCode, vehicleIds: vehicleIds.join(',') };
        return this.http.get<APIResponseWrapper<Vehicle[]>>(`${this.baseUrl}/vehicles`, { params });
    }

    addVehicle(vehicle: Vehicle)  {
        return this.http.post<APIResponseWrapper<Vehicle>>(`${this.baseUrl}/vehicles`, vehicle);
    }

    updateVehicle(vehicle: Vehicle) {
        return this.http.put<APIResponseWrapper<Vehicle>>(`${this.baseUrl}/vehicles`, vehicle);
    }

    deleteVehicle(vehicle: Vehicle) { // Adjust return type if needed
        return this.http.delete(`${this.baseUrl}/vehicles`, { body: vehicle });
    }

    findVehiclesByCompanyId(page: number, pageSize: number, companyId: number)  {
        const params = { page: page.toString(), pageSize: pageSize.toString(), companyId: companyId.toString() };
        return this.http.get<APIListWrapper<Vehicle>>(`${this.baseUrl}/vehicles-by-company`, { params });
    }
}

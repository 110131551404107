import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/common/auth.service';
import { MenuItem } from 'primeng/api';
import { SessionStorageService } from './services/common/session-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolbarService } from './services/common/toolbar.service';
import { Customer } from './models/customer/customer.model';
import { Company } from './models/customer/company.model';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	private roles: string[] = [];
	isLoggedIn: boolean = false;
	customer: Customer | null = null;
	company: Company | null = null;
	returnUrl = '';
	//showAdminBoard = false;
	//showModeratorBoard = false;
	username?: string;
	title = 'cargomate-portal';
	toolbarMenuItems: MenuItem[] = [];
	profileMenuItems: MenuItem[] = [];

	email: string = '';
	password: string = '';

	constructor(private authService: AuthService,
		private sessionStorageService: SessionStorageService,
		private router: Router, private route: ActivatedRoute,
		private toolbarService: ToolbarService) { }

	ngOnInit() {
		this.toolbarService.isLoggedInObservable.subscribe(isLoggedIn => {
			const user = this.sessionStorageService.getUser();
			this.isLoggedIn = user != null;
			console.log('This is the updated logged in status: ', isLoggedIn);
			if (this.isLoggedIn) {
				this.roles = user.roles;
				this.authService.getCurrentUserAccessToken().subscribe(response => {
					this.sessionStorageService.saveAccessToken(response, new Date().getTime());
				})
				this.username = user.username;
			} else {
				this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.href } });
			}
		});

		this.toolbarService.customerObservable.subscribe(customer => {
			if (customer !== null) {
				this.customer = customer;
				this.company = customer?.company!!;
			} else if (this.sessionStorageService.getCustomer()) {
				this.customer = this.sessionStorageService.getCustomer()!;
				this.company = this.customer?.company;
			};
			console.log('This is the updated customer: ', JSON.stringify(this.customer));
		})

		this.toolbarMenuItems = [{
			label: 'Dashboard', icon: 'fa-solid fa-home',
			routerLink: ['/dashboard'],
		},
		{
			label: 'Orders', icon: 'fa-solid fa-truck-fast',
			items: [
				{ label: 'Create Order', icon: 'fa-solid fa-folder-plus', routerLink: ['/order/create'] },
				{ label: 'My Current Orders', icon: 'fa-regular fa-square-caret-right', routerLink: ['/order'] },
				{ label: 'Current Company Orders', icon: 'fa-solid fa-truck-fast', routerLink: ['/order/company'] }
			]
		},
		{
			label: 'Accounts', icon: 'fa-solid fa-cash-register',
			items: [
				{ label: 'Invoices', icon: 'fa-solid fa-file-invoice', routerLink: ['/accounts/invoice'] },
				{ label: 'Receipts', icon: 'fa-regular fa-receipt', routerLink: ['/accounts/receipt'] }
			]
		},
		{
			label: 'Locations',
			icon: 'fa-solid fa-mountain-city',
			routerLink: ['/location'],
			items: [
				{ label: 'Customers', icon: 'fa fa-user' },
				{ label: 'Warehouses', icon: 'fa fa-warehouse' }
			]
		}];
		this.profileMenuItems = [{
			label: 'Sign Out', icon: 'pi pi-fw pi-user'
		},
		{
			label: 'Settings', icon: 'pi pi-fw pi-settings'
		}];
	}

	signUp() {
		this.authService.register(this.email, this.password);
		this.email = '';
		this.password = '';
	}

	signIn() {
		this.authService.login(this.email, this.password);
		this.email = '';
		this.password = '';
	}

	signOut() {

	}

	logout(): void {
		this.sessionStorageService.signOut();
		this.toolbarService.updateLoggedInStatus(false);
		window.location.reload();
		this.authService.logout();
	}

}

/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { SessionStorageService } from '../services/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';
import { Company } from './company.model';

@Injectable()
export class CompanyService {

    restApiUrl: string

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getCompanyById(companyId: number) {
        return this.http.get<APIResponseWrapper<Company>>(`${this.restApiUrl}company-endpoint/companies?companyId=${companyId}`);
    }

    getCompaniesByCountry(countryId: number) {
        return this.http.get<Company[]>(`${this.restApiUrl}company-endpoint/companies-by-country?countryId=${countryId}`);
    }



}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB0Rm53ex66t2XT0zqSIz_MbWriblITy40",
    authDomain: "prologix-test.firebaseapp.com",
    databaseURL: "https://prologix-test-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "prologix-test",
    storageBucket: "prologix-test.appspot.com",
    messagingSenderId: "862331447268",
    appId: "1:862331447268:web:36c8d51a66d2604281c9f1",
    measurementId: "G-LP3TTXKG7P"
  },
  googleApiKey: "AIzaSyAcMcFP-5pRJ1AJGLBkhwKSYHK-oS4m4Ag",
  reactiveApiUrl: "https://transaction-api-7tg7bixpgq-el.a.run.app/api/",
  paymentApiUrl: "https://payment-api-7tg7bixpgq-el.a.run.app/api/",
  restApiUrl: "https://rest-api-7tg7bixpgq-el.a.run.app/api/",
  reportsApiUrl: "https://reports-api-7tg7bixpgq-el.a.run.app/api/",
  bigQueryDatasetPath: "prologix-test"
};

// Initialize Firebase
export const app = initializeApp(environment.firebaseConfig);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Location } from '../models/location/location.model';
import { SessionStorageService } from '../services/common/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';
import { Invoice } from './models/invoice.model';
import { Receipt } from './models/receipt.model';
import {APIListWrapper} from "../models/common/api-list-wrapper.model";

@Injectable()
export class AccountsService {

    restApiUrl: string

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    findAllInvoicesByCompany(page: number, pageSize: number) {
        return this.http.get<APIListWrapper<Invoice>>(`${this.restApiUrl}accounts-endpoint/invoices`
            + `?companyId=${this.sessionStorageService.getCompany()?.id}`
            + `&page=${page}`
            + `&pageSize=${pageSize}`);
    }

    findAllReceiptsByCompany(page: number, pageSize: number) {
        return this.http.get<APIListWrapper<Receipt>>(`${this.restApiUrl}accounts-endpoint/receipts`
            + `?companyId=${this.sessionStorageService.getCompany()?.id}`
            + `&page=${page}`
            + `&pageSize=${pageSize}`);
    }


}

<p-toast></p-toast>
<p-dialog [header]="dialogHeader" [(visible)]="displayModal" [modal]="true" [style]="{ width: '50vw' }"
          [draggable]="false" [resizable]="false">
    <div class="p-fluid card">
        <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
            <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-user"></i></span>
                            <input pInputText id="name" type="text" formControlName="name" [(ngModel)]="customer.name" placeholder="Full Name" required>
                            <small class="p-error" *ngIf="customerForm.get('name')?.invalid && (customerForm.get('name')?.dirty || customerForm.get('name')?.touched)">Name is required.</small>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-envelope"></i></span>
                            <input pInputText id="emailAddress" type="email" formControlName="emailAddress" [(ngModel)]="customer.emailAddress" placeholder="Email Address" required>
                            <small class="p-error" *ngIf="customerForm.get('emailAddress')?.invalid && (customerForm.get('emailAddress')?.dirty || customerForm.get('emailAddress')?.touched)">Valid email is required.</small>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-phone"></i></span>
                            <input pInputText id="phoneNumber" type="tel" formControlName="phoneNumber" [(ngModel)]="customer.phoneNumber" placeholder="Mobile Number" required>
                            <small class="p-error" *ngIf="customerForm.get('phoneNumber')?.invalid && (customerForm.get('phoneNumber')?.dirty || customerForm.get('phoneNumber')?.touched)">Phone number is required.</small>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row" *ngIf="!company">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-building"></i></span>
                            <p-dropdown [options]="companies" optionLabel="name" optionValue="id"
                                        id="companyId" formControlName="companyId" placeholder="Select a Company" [required]="true">
                            </p-dropdown>
                            <small class="p-error" *ngIf="customerForm.get('companyId')?.invalid && (customerForm.get('companyId')?.dirty || customerForm.get('companyId')?.touched)">Company is required.</small>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="onCancel()" class="p-button-secondary"></button>
        <button pButton type="submit" [label]="submitButtonLabel" (click)="onSubmit()"></button>
    </ng-template>
</p-dialog>

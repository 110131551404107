import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {Customer} from "../customer.model";
import {Company} from "../../company/company.model";
import {CustomerService} from "../customer.service";
import {CompanyService} from "../../company/company.service";



@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit {
    customerForm!: FormGroup;
    @Input() displayModal: boolean = false;
    @Input() dialogHeader: string = '';
    @Input() submitButtonLabel: string = '';
    @Input() customer: Customer;
    @Input() company: Company;
    @Output() modalClosed = new EventEmitter<boolean>();
    companies: Company[] = [];

    constructor(
        private fb: FormBuilder,
        private customerService: CustomerService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.loadCompanies();
        this.initializeForm();
    }

    initializeForm() {
        this.customerForm = this.fb.group({
            name: [this.customer.name, Validators.required],
            emailAddress: [this.customer.emailAddress, [Validators.required, Validators.email]],
            phoneNumber: [this.customer.phoneNumber, Validators.required],
            customerId: [this.customer.id, Validators.required],
            companyId: [this.customer.id, Validators.required]
        });
    }

    loadCompanies() {
        this.companyService.getCompaniesByCountry(2).subscribe(companies => {
            this.companies = companies;
        });
    }

    onSubmit() {
        //const customerData = this.customerForm.value;
        if (this.customer.id) {
            this.updateCustomer(this.customer);
        } else {
            this.createCustomer(this.customer);
        }
    }

    createCustomer(customerData: any) {
        this.customerService.createCustomer(customerData).then(() => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Customer created successfully!'});
            this.closeModal();
            this.router.navigate(['/company']);
        }, error => {
            console.error('Error creating customer:', error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to create customer. Please try again later.'
            });
        });
    }

    updateCustomer(customerData: any) {
        this.customerService.updateCustomer(customerData).then(() => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Customer updated successfully!'});
            this.closeModal();
            this.router.navigate(['/company']);
        }, error => {
            console.error('Error updating customer:', error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update customer. Please try again later.'
            });
        });
    }

    onCancel() {
        this.closeModal();
    }

    closeModal() {
        this.displayModal = false;
        this.modalClosed.emit(true);
    }
}

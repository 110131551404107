/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment'
import {Customer} from './customer.model';
import {APIResponseWrapper} from '../models/common/api-response-wrapper';
import {Role} from "./customer-role.model";
import {AuthService} from "../services/auth.service";

@Injectable()
export class CustomerService {

    restApiUrl: string

    constructor(private http: HttpClient, private authService: AuthService) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getCustomerById(firebaseId: string) {
        return this.http.get<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer?customerId=${firebaseId}`);
    }

    getCustomerByMobileNumber(phoneNumber: string) {
        return this.http.get<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer?phoneNumber=${phoneNumber.replace('+','%2B')}`);
    }

    async createCustomer(customer: Customer) {
        customer.firebaseId = await this.authService.createFirebaseUser(customer.emailAddress, customer.phoneNumber);
        return this.http.post<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer`, customer)
    }

    async updateCustomer(customer: Customer) {
        await this.authService.updateFirebaseUser(customer.firebaseId, customer.emailAddress, customer.phoneNumber);
        return this.http.put<APIResponseWrapper<Customer>>(`${this.restApiUrl}customer-endpoint/customer`, customer)
    }

    getCustomersByCompany(companyId: number) {
        const params = { companyId: companyId.toString() }; // Ensure companyId is a string
        return this.http.get<Customer[]>(`${this.restApiUrl}customer-endpoint/customers-by-company`, { params });
    }

    getAllRoles() {
        return this.http.get<Role[]>(`${this.restApiUrl}customer-endpoint/roles`);
    }

    getRolesByCustomerId(customerId: number) {
        return this.http.get<Role[]>(`${this.restApiUrl}customer-endpoint/${customerId}/roles`);
    }

    addRoleToCustomer(customerId: number, role: Role) {
        return this.http.post<Role>(`${this.restApiUrl}customer-endpoint/${customerId}/roles`, role);
    }

    deleteRoleFromCustomer(customerId: number, roleId: number) {
        return this.http.delete<void>(`${this.restApiUrl}customer-endpoint/${customerId}/roles/${roleId}`);
    }

    modifyRoleForCustomer(customerId: number, roleId: number, role: Role) {
        return this.http.put<Role>(`${this.restApiUrl}customer-endpoint/${customerId}/roles/${roleId}`, role);
    }

    modifyRolesForCustomer(customerId: number, roles: Role[]) {
        return this.http.put<Role>(`${this.restApiUrl}customer-endpoint/${customerId}/roles`, roles);
    }


}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { CreateOrderComponent } from './order/create-order/create-order.component';

import { LoginComponent } from './login/login.component';
import { AuthService } from './services/common/auth.service';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { FirebaseUIModule, firebase } from "firebaseui-angular";

import { SlideMenuModule } from 'primeng/slidemenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag'
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AccordionModule } from 'primeng/accordion';
import { GalleriaModule } from 'primeng/galleria';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TimelineModule } from 'primeng/timeline';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar'
import { ChartModule } from 'primeng/chart';
import { FileUploadModule } from 'primeng/fileupload';

import { OrderQueryService } from './services/order-query.service';
import { CustomerService } from './services/customer.service';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { GoogleMapsModule, MapDirectionsRenderer } from '@angular/google-maps';

import { FontAwesomeModule, FaIconLibrary, } from '@fortawesome/angular-fontawesome';
import { BaseMasterDataService } from './services/base-master-data.service';
import { GoogleMapsLibrariesLoaderService } from './services/google/google-libraries-loader.service';
import { GoogleMapsService } from './services/google/google-maps-loader.service';
import { MessageService } from 'primeng/api';
import { CompanyService } from './services/company.service';
import { LocationService } from './services/location.service';
import { OrderJobService } from './services/order-job.service';
import { OrderDeliveryService } from './services/order-delivery.service';
import { ReportsService } from './services/reports.service';
import { OrderComponent } from './order/order.component';
import { DeliveryComponent } from './order/delivery/delivery.component';
import { LocationComponent } from './location/location.component';
import { ViewOrderComponent } from './order/view-order/view-order.component';
import { CreateLocationComponent } from './location/create-location/create-location.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BigQueryService } from './services/bigquery-api.service';
import { AccountsComponent } from './accounts/accounts.component';
import { InvoiceComponent } from './accounts/invoice/invoice.component';
import { ReceiptComponent } from './accounts/receipt/receipt.component';
import { AccountsService } from './accounts/accounts.service';
import * as firebaseui from 'firebaseui';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { OrderCompanyComponent } from './order/company/order-company.component';
import { GoogleCloudStorageService } from './services/google/google-cloud-storage.service';
import { FileUploadComponent } from './helpers/file-upload/file-upload.component';
import { DialogService } from 'primeng/dynamicdialog';


const firebaseUiAuthConfig: firebaseui.auth.Config = {
	signInFlow: 'popup',
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		{
			requireDisplayName: false,
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
		},
		firebase.auth.PhoneAuthProvider.PROVIDER_ID,
		{
			scopes: [
				'public_profile',
				'email'
			],
			customParameters: {
				'auth_type': 'reauthenticate'
			},
			provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
		},
		firebase.auth.TwitterAuthProvider.PROVIDER_ID,
		'microsoft.com',
	],
	tosUrl: 'https://www.cargomate.co/privacy-policy',
	privacyPolicyUrl: 'https://www.cargomate.co/privacy-policy',
	credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};



@NgModule({
	declarations: [
		AppComponent,
		CreateOrderComponent,
		ViewOrderComponent,
		OrderComponent,
		LoginComponent,
		DeliveryComponent,
		DeliveryComponent,
		LocationComponent,
		CreateLocationComponent,
		DashboardComponent,
		AccountsComponent,
		InvoiceComponent,
		ReceiptComponent,
		OrderCompanyComponent,
		FileUploadComponent
	],
	imports: [
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFireFunctionsModule,
		BrowserModule,
		AvatarModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SlideMenuModule,
		OverlayPanelModule,
		MenuModule,
		FormsModule,
		AccordionModule,
		GalleriaModule,
		BreadcrumbModule,
		TimelineModule,
		FontAwesomeModule,
		TableModule,
		MenubarModule,
		InputTextModule,
		DropdownModule,
		InputNumberModule,
		CalendarModule,
		DataViewModule,
		CardModule,
		CheckboxModule,
		ToastModule,
		ImageModule,
		TagModule,
		DialogModule,
		ProgressSpinnerModule,
		ProgressBarModule,
		ChartModule,
		FileUploadModule,
		DividerModule,
		GoogleMapsModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		FirebaseUIModule.forRoot(firebaseUiAuthConfig),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the service worker
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		AuthService,
		OrderQueryService,
		OrderJobService,
		OrderDeliveryService,
		CustomerService,
		CompanyService,
		LocationService,
		ReportsService,
		BaseMasterDataService,
		GoogleMapsService,
		GoogleCloudStorageService,
		MessageService,
		BigQueryService,
		AccountsService,
		MapDirectionsRenderer,
		GoogleMapsLibrariesLoaderService,
		DialogService,
		authInterceptorProviders
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) { }
}

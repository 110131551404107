/* eslint-disable */
import { VehicleCapacity } from "../vehicleparams/vehicle-capacity";
import { VehicleConfig } from "../vehicleparams/vehicle-config";
import { VehicleType } from "../vehicleparams/vehicle-type";

export class VehicleParaPackView {

    vehicleType: VehicleType | undefined;
    vehicleConfig: VehicleConfig | undefined;
    vehicleCapacity: VehicleCapacity | undefined;

}
import { Country } from "../models/common/country";
import { GeoAddress } from "../models/common/geo-address.model";
import { LatLng } from "../models/common/lat-lng";
import { Company } from "../company/company.model";
import { Customer } from "../customer/customer.model";


export class Location {

    id: number;
    address: GeoAddress;
    country: Country;
    company: Company;
    defaultContact: Customer;
    location: LatLng;
    name: string;
    longDescription: string;
    shortDescription: string;
    telephoneNumber: string;
    placeId: string;
    managedBy: Company;
    roomNumber: string;
    floorNumber: string;
    buildingNumber: string;
    nearbyLandmark: string;
    frontDoorImage: string;
    buildingImage: string;
    isWarehouse: boolean;
    locationType: string;
    helperImage1: string;
    helperImage2: string;
    helperImage3: string;
    externalLocationReference: string;

}

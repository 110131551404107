<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="locations" class="m-2">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Locations</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Location" icon="pi pi-plus" (onClick)="addLocation()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Address</th>
            <th>Company</th>
            <th>Type</th>
            <th>Contact</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-location>
        <tr>
            <td>{{ location.externalLocationReference }}</td>
            <td><a href="https://maps.google.com/?q={{ location.location.latitude }},{{ location.location.longitude }}" target="_blank">{{ location.name }}</a></td>
            <td>{{ getLocationAddress(location) }}</td>
            <td>{{ location.company.name }}</td>
            <td>{{ location.locationType }}</td>
            <td>{{ location.defaultContact.name }}</td>
            <td>
                <p-button icon="pi pi-eye" (onClick)="viewLocation(location)" class="pr-1 pt-1 p-button-info"></p-button>
                <p-button icon="pi pi-pencil" (onClick)="editLocation(location)"  class="pr-1 pt-1 p-button-secondary"></p-button>
                <p-button icon="pi pi-trash" (onClick)="deleteLocation(location)" class="pr-1 pt-1 p-button-danger"></p-button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{locationsCount}} locations.
        </div>
    </ng-template>
</p-table>
import { Component } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { OrderQueryView } from '../../models/order-view/order-query-view';
import { OrderQueryService } from '../shipment-query.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { MenuItem } from 'primeng/api';
import { DataViewLazyLoadEvent } from 'primeng/dataview';


@Component({
  selector: 'app-company',
  templateUrl: './shipment-company.component.html',
  styleUrls: ['./shipment-company.component.css']
})
export class ShipmentCompanyComponent {

  fullOrderQueryList: OrderQueryView[];
  orderQueryList: OrderQueryView[] = [];
	orderQueryMap: Map<number, OrderQueryView[]> = new Map<number, OrderQueryView[]>();
	isLoading = true;
  currentPage: number = 0;
	totalRecords: number | undefined;
	breadcrumbItems: MenuItem[];
	homeBreadcrumb: MenuItem;

	constructor(private orderQueryService: OrderQueryService,
		private sessionStorageService: SessionStorageService,
		private router: Router) {
      this.fullOrderQueryList = [];
    }

	ngOnInit(): void {
		this.breadcrumbItems = [{ label: 'Shipments' }];
		this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
	}

	loadOrderList($event: DataViewLazyLoadEvent) {
    this.currentPage = Math.floor($event.first / $event.rows) + 1;
		if (this.orderQueryMap.has(this.currentPage))
			this.orderQueryList = this.orderQueryMap.get(this.currentPage) || [];
		else {
			this.isLoading = true;
			this.orderQueryService.getOrdersByCompanyPaginated(this.currentPage-1, $event.rows, this.sessionStorageService.getCompany()?.id!!)
			.subscribe((orders: OrderQueryView[]) => {
        this.fullOrderQueryList.push(...orders);
        this.orderQueryMap.clear();
        this.orderQueryList = [];
        for(let i = 0; i < this.fullOrderQueryList.length/$event.rows; i++)
          this.orderQueryMap.set(i+1, this.fullOrderQueryList.slice(i, i + $event.rows));
        this.orderQueryList = this.orderQueryMap.get(this.currentPage) || [];
				this.isLoading = false;
			});
		}
		this.orderQueryService.getOrdersByCompanyCount(this.sessionStorageService.getCompany()?.id!!)
			.subscribe((ordersCount: number) => {
				console.log("Total Records: " + ordersCount);
				this.totalRecords = ordersCount;
			});
	}

	showDate(longDate: number) {
		return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
	}

	viewDetailPage(orderQuery: OrderQueryView): void {
		this.router.navigate(
			['/shipment/view', orderQuery.queryId]
		);
	}

}

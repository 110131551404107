import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderQueryView } from '../../models/order-view/order-query-view';
import { SessionStorageService } from '../../services/session-storage.service';
import { VehicleType } from '../../fleet/vehicle/params/vehicle-type';
import { PayloadType } from '../../models/order-params/payload-type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleMapsLibrariesLoaderService } from '../../services/google/google-libraries-loader.service';
import { PayloadItemView } from '../../models/order-params/payload-item-view';
import { VehicleConfig } from '../../fleet/vehicle/params/vehicle-config';
import { VehicleCapacity } from '../../fleet/vehicle/params/vehicle-capacity';
import { LatLng } from '../../models/common/lat-lng';
import { QuantityView } from '../../models/order-params/quantity-view';
import { OrderQueryService } from '../shipment-query.service';
import { Company } from '../../company/company.model';
import { MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Location } from '../../location/location.model';
import {LocationService} from "../../location/location.service";

@Component({
    selector: 'app-create-shipment',
    templateUrl: './create-shipment.component.html',
    styleUrls: ['./create-shipment.component.css']
})
export class CreateShipmentComponent implements OnInit, AfterViewInit {

    createOrderForm: FormGroup;
    sourceLocationDropdown: FormControl;
    destinationLocationDropdown: FormControl;
    vehicleTypeDropdown: FormControl;
    vehicleConfigDropdown: FormControl;
    vehicleCapacityDropdown: FormControl;
    payloadTypeDropdown: FormControl;
    pickupDateSelect: FormControl;
    quantityRequiredInput: FormControl;
    uomQuantityInput: FormControl;
    itemWeightInput: FormControl;

    googleMap: any;
    orderQuery: OrderQueryView = new OrderQueryView();
    filteredLocations: Location[] = [];
    vehicleTypes: VehicleType[] = [];
    vehicleConfigurations: VehicleConfig[] = [];
    vehicleCapacities: VehicleCapacity[] = [];
    payloadTypes: PayloadType[] = [];
    companyList: Company[] = [];

    orderCreationInProgress = false;
    currencyValue = null
    quantityRequired: any = null;
    totalWeight: number = 0;
    pickupDate: Date | null = null;
    searchPlaceResults: string[];

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(private sessionStorageService: SessionStorageService,
                private readonly googleMapsLibrariesLoaderService: GoogleMapsLibrariesLoaderService,
                private orderQueryService: OrderQueryService, private router: Router,
                private messageService: MessageService, private locationService: LocationService) {
        this.vehicleTypes = sessionStorageService.getVehicleTypes()!!;
        this.payloadTypes = sessionStorageService.getPayloadTypes()!!;
        this.orderQuery.payloadItemList.push(new PayloadItemView());
        this.orderQuery.payloadItemList[0]!!.itemQuantity = new QuantityView();
        this.orderQuery.countryOfOrder = this.sessionStorageService.getCountryList()?.find(country => country.countryCode == 'SA')!!;
    }

    ngAfterViewInit(): void {
        this.googleMapsLibrariesLoaderService.lazyLoadMap().subscribe(_ =>
            this.googleMap = new google.maps.Map(document.getElementById("map") as HTMLElement));
        if (!navigator.geolocation) {
            console.log('location is not supported');
        }
        navigator.geolocation.getCurrentPosition((position) => {
            this.orderQuery.searchLocation = new LatLng(position.coords.latitude, position.coords.longitude);
        });
    }

    ngOnInit(): void {
        this.createOrderForm = new FormGroup({
            sourceLocationDropdown: new FormControl('', Validators.required),
            destinationLocationDropdown: new FormControl('', Validators.required),
            pickupDateSelect: new FormControl('', Validators.required),
            vehicleTypeDropdown: new FormControl('', Validators.required),
            vehicleConfigDropdown: new FormControl('', Validators.required),
            vehicleCapacityDropdown: new FormControl('', Validators.required),
            quantityRequiredInput: new FormControl('', Validators.required),
            payloadTypeDropdown: new FormControl('', Validators.required),
            uomQuantityInput: new FormControl('', Validators.required),
            itemWeightInput: new FormControl('', Validators.required)
        });

        this.breadcrumbItems = [{ label: 'Orders', routerLink: '/shipment' }, { label: 'Create' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    filterLocations(event: any) {
        const query = event.query;
        this.locationService.getAllComboData(query, this.sessionStorageService.getCompany()?.id ?? 0, 0, 100, 'en-US') // Adjust page size as needed
            .subscribe(data => {
                this.filteredLocations = data.items;
            });
    }

    onLocationSelect(type: 'source' | 'destination') {
        if (type === 'source') {
            // Handle source location selection (e.g., update orderQuery.sourceLocation)
        } else {
            // Handle destination location selection (e.g., update orderQuery.destinationLocation)
        }
        this.placeSelect(); // Call your distance calculation function
    }

    placeSelect(): void {
        if (this.orderQuery.sourceLocation && this.orderQuery.destinationLocation) {
            const distanceMatrixService = new google.maps.DistanceMatrixService();
            const parameters = {
                origins: [new google.maps.LatLng(this.orderQuery.sourceLocation.location.latitude?.valueOf()!!, this.orderQuery.sourceLocation.location.longitude?.valueOf()!!)],
                destinations: [new google.maps.LatLng(this.orderQuery.destinationLocation.location.latitude?.valueOf()!!, this.orderQuery.destinationLocation.location.longitude?.valueOf()!!)],
                travelMode: google.maps.TravelMode['DRIVING'],
                unitSystem: google.maps.UnitSystem.METRIC, // kilometers and meters.
                avoidHighways: false,
                avoidTolls: false
            };
            distanceMatrixService.getDistanceMatrix(parameters, (response: google.maps.DistanceMatrixResponse | null, status: google.maps.DistanceMatrixStatus) => {
                if (status != google.maps.DistanceMatrixStatus.OK) {
                } else {
                    if (response?.rows[0]?.elements[0]?.status === 'ZERO_RESULTS') {
                    } else {
                        const distance = response?.rows[0]?.elements[0]?.distance;
                        const duration = response?.rows[0]?.elements[0]?.duration;
                        this.orderQuery.distance = distance?.value!!;
                        this.orderQuery.time = duration?.value!!
                    }
                }
            });
        }

    }

    vehicleTypeSelected(): void {
        if (this.orderQuery.vehicleParaPack.vehicleType) {
            this.vehicleConfigurations = this.orderQuery.vehicleParaPack.vehicleType.vehicleConfigs;
            this.vehicleCapacities = this.orderQuery.vehicleParaPack.vehicleType.vehicleCapacities;
        }
    }

    submitOrder() {
        try {
            this.orderCreationInProgress = true;
            this.orderQuery.requestedBookingOption = "BOOK_LATER";
            this.orderQuery.requestedBookingTime = this.pickupDate?.valueOf()!!;
            this.orderQuery.customer = this.sessionStorageService.getCustomer()!!;

            this.orderQuery.orderType = 'ENTERPRISE';

            this.orderQuery.payloadItemList[0]!!.packingListIndex = 1;
            this.orderQuery.payloadItemList[0]!!.itemQuantity!!.uom
                = this.sessionStorageService.getUOMList()?.find(uom => uom.name == 'Pallet');
            this.orderQuery.payloadItemList[0]!!.weightUnit
                = this.sessionStorageService.getWeightUnits()?.find(weight => weight.shortName == 'ton');
            this.orderQuery.payloadItemList[0]!!.itemWeight
                = this.totalWeight / this.orderQuery.payloadItemList[0]!!.itemQuantity!!.quantity!!;

            this.orderQuery.creationDate = Date.now();
            this.orderQuery.orderStatus = 'DRAFT';

            if (this.orderQuery.vehicleParaPack.vehicleConfig == null)
                this.orderQuery.vehicleParaPack.vehicleConfig = this.orderQuery.vehicleParaPack.vehicleType?.vehicleConfigs[0];
            if (this.orderQuery.vehicleParaPack.vehicleCapacity == null)
                this.orderQuery.vehicleParaPack.vehicleCapacity = this.orderQuery.vehicleParaPack.vehicleType?.vehicleCapacities[0];
            this.orderQuery.vehicleParaPack.vehicleType!!.vehicleConfigs = []
            this.orderQuery.vehicleParaPack.vehicleType!!.vehicleCapacities = []

            this.orderQuery.sellingPrice = 100;
            this.orderQuery.buyingPrice = 90;

            let errorMessages = '';
            if (this.orderQuery.sourceLocation == null)
                errorMessages += "Source Location needs to be entered";
            if (this.orderQuery.destinationLocation == null)
                errorMessages += "Destination Location needs to be entered";
            if (this.orderQuery.requestedBookingTime == null)
                errorMessages += "Pickup date needs to be entered";
            if (this.orderQuery.payloadItemList.length == 0 || this.orderQuery.payloadItemList[0].itemType == null)
                errorMessages += "Cargo type should be added";
            if (this.orderQuery.payloadItemList.length == 0 || this.orderQuery.payloadItemList[0].itemQuantity == null)
                errorMessages += "Pallets count should be added";

            //this.createOrderForm.

            if (errorMessages.length > 0)
                throw new Error(errorMessages);
        }
        catch (e) {
            let errorMessage = null;
            if (typeof e === "string") {
                errorMessage = e.toUpperCase() // works, `e` narrowed to string
            } else if (e instanceof Error) {
                errorMessage = e.message // works, `e` narrowed to Error
            }
            this.orderCreationInProgress = true;
            this.messageService.add({
                severity: 'error', summary: 'Order creation failed!',
                detail: 'Your order creation failed due to the following reason: ' + errorMessage
            });
        }

        console.log('Order Query Request: ' + JSON.stringify(this.orderQuery));
        this.orderQueryService.createOrder(this.orderQuery, 'en-US')
            .subscribe(createdOrderResponse => {
                this.orderCreationInProgress = false;
                console.log('Order Query Response: ' + JSON.stringify(createdOrderResponse));
                if (createdOrderResponse.success) {
                    this.orderQuery = createdOrderResponse.data;
                    this.messageService.add({
                        severity: 'success', summary: 'Order# ' + createdOrderResponse.data.queryId + ' is created',
                        detail: 'Order# ' + createdOrderResponse.data.queryId + ' has been successfully created. Our team will notify you when trucks are assigned to your order'
                    });
                    this.router.navigate(
                        ['/order/view', createdOrderResponse.data.queryId]
                    );
                } else {
                    this.messageService.add({
                        severity: 'error', summary: 'Order creation failed!',
                        detail: 'Your order creation failed due to the following reason: ' + createdOrderResponse.message
                    });
                }
            });
    }

    addLocation() {
        this.router.navigate(['/location/create']);
    }

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {DialogService} from "primeng/dynamicdialog";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";
import {AvatarModule} from "primeng/avatar";
import {TagModule} from "primeng/tag";
import {TimelineModule} from "primeng/timeline";
import {AccordionModule} from "primeng/accordion";
import {GalleriaModule} from "primeng/galleria";

import {contractRoutes} from "./contracts.routes";
import {GoogleCloudStorageService} from "../services/google/google-cloud-storage.service";
import {InputNumberModule} from "primeng/inputnumber";
import {CalendarModule} from "primeng/calendar";
import {MessageService} from "primeng/api";
import {ContractsComponent} from "./contracts.component";
import {ViewContractComponent} from "./view-contract/view-contract.component";
import {AddEditContractComponent} from "./add-edit-contract/add-edit-contract.component";
import {AddEditContractRevisionComponent} from "./add-edit-contract-revision/add-edit-contract-revision.component";
import {AddEditRateCardComponent} from "./add-edit-rate-card/add-edit-rate-card.component";
import {ContractsService} from "./contracts.service";
import {RadioButtonModule} from "primeng/radiobutton";
import {TabViewModule} from "primeng/tabview";
import {FileUploadModule} from "primeng/fileupload";
import {PanelModule} from "primeng/panel";
import {InputSwitchModule} from "primeng/inputswitch";




@NgModule({
    imports: [
        RouterModule.forChild(contractRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        AvatarModule,
        TagModule,
        ButtonModule,
        TableModule,
        DropdownModule,
        AutoCompleteModule,
        TimelineModule,
        AccordionModule,
        GalleriaModule,
        InputTextModule,
        InputNumberModule,
        CalendarModule,
        RadioButtonModule,
        TabViewModule,
        FileUploadModule,
        PanelModule,
        InputSwitchModule,
    ],
    providers: [
        MessageService,
        DialogService,
        ContractsService,
        GoogleCloudStorageService,
    ],
    declarations: [
        ContractsComponent,
        ViewContractComponent,
        AddEditContractComponent,
        AddEditContractRevisionComponent,
        AddEditRateCardComponent
    ],
})
export class ContractsModule { }

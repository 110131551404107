import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Driver} from "../../../fleet/driver/driver.model";
import {Vehicle} from "../../../fleet/vehicle/vehicle.model";
import {DriverService} from "../../../fleet/driver/driver.service";
import {VehicleService} from "../../../fleet/vehicle/vehicle.service";
import {SessionStorageService} from "../../../services/session-storage.service";

@Component({
    selector: 'app-generate-deliveries',
    templateUrl: './generate-deliveries.component.html',
    styleUrls: ['./generate-deliveries.component.css']
})
export class GenerateDeliveriesComponent implements OnInit {

    availableDrivers: Driver[] = [];
    selectedDrivers: Driver[] = [];
    availableVehicles: Vehicle[] = [];
    selectedVehicles: Vehicle[] = [];
    isLoading: boolean = false;
    deliveryGenerationInProgress: boolean = false;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(
        private driverService: DriverService,
        private vehicleService: VehicleService,
        private messageService: MessageService,
        private router: Router, private route: ActivatedRoute,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.loadDrivers();
        this.loadVehicles();

        // Get driver ID from route parameters
        const queryId = this.route.snapshot.paramMap.get('orderNumber');

        this.breadcrumbItems = [{ label: 'Orders', routerLink: '/shipment' },
            { label: queryId!, routerLink: ['/shipment/view', queryId] },
            { label: 'Generate Deliveries' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    loadDrivers() {
        this.isLoading = true;
        const agencyId = this.sessionStorageService.getCompany()?.transportationAgencyId;
        if (agencyId) {
            this.driverService.findDriversByTransportationAgencyId(0, 1000, agencyId) // Adjust page size as needed
                .subscribe(response => {
                    this.availableDrivers = response.items;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Agency ID not found.' });
        }
    }

    loadVehicles() {
        this.isLoading = true;
        const agencyId = this.sessionStorageService.getCompany()?.transportationAgencyId;
        if (agencyId) {
            this.vehicleService.findVehiclesByCompanyId(0, 1000, agencyId) // Adjust page size as needed
                .subscribe(response => {
                    this.availableVehicles = response.items;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Agency ID not found.' });
        }
    }

    generateDeliveries() {
        this.deliveryGenerationInProgress = true;
        // Implement your logic to generate deliveries based on selected drivers and vehicles
        // ...

        // Example: Display a success message and navigate to the deliveries page
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deliveries generated successfully.' });
        this.router.navigate(['/deliveries']);
        this.deliveryGenerationInProgress = false;
    }

    clearSelections() {
        this.selectedDrivers = [];
        this.selectedVehicles = [];
    }
}

import { Component } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { SessionStorageService } from '../services/common/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent {

  selectedDateRange: Calendar;
  totalShipments: number;
  totalShipmentAmount: number;
  invoicedAmountPending: number;
  settlementsMade: number;
  topPickupCitiesData: any;
  topDropoffCitiesData: any;
  weightTransportedData: any;
  chartOptions: any;


  constructor(private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    // Initialize chart options
    this.chartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    };

    // Fetch initial data (replace with your actual data fetching logic)
    this.fetchDashboardData();
  }

  onDateRangeChange(): void {
    // Fetch data based on the selected date range
    this.fetchDashboardData();
  }

  async fetchDashboardData() {
    

    this.totalShipmentAmount = 567890;
    this.invoicedAmountPending = 12345;
    this.settlementsMade = 67890;


    this.weightTransportedData = {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [{
        label: 'Weight (kg)',
        data: [1000, 1200, 800, 1500, 900],
        borderColor: '#42A5F5',
        fill: false
      }]
    };



  }
}

<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
  <form class="overFlow" [formGroup]="createEditLocationForm" novalidate autocomplete="off" autocorrect="off"
    autocapitalize="off" spellcheck="off">
    <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
      <div class="mr-5 flex align-items-center mt-3">
        <i class="fa-solid fa-building mr-2" style="font-size: 1.5rem"></i>
        <div class="font-medium text-xl text-900">Create/Update Location</div>
      </div><br>
      <div class="flex flex-column lg:flex-row">
        <div class="flex col">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fa-solid fa-map-location-dot"></i></span>
            <p-autoComplete formControlName="searchLocation"
              [ngClass]="{'p-invalid': ((createEditLocationForm.get('search')?.touched || createEditLocationForm.get('search')?.dirty) )}"
              [autofocus]="true" [forceSelection]="true" placeholder="Location Search" autocorrect="off"
              autocapitalize="off" spellcheck="off" [suggestions]="searchPlaceResults"
              (completeMethod)="searchPlaces($event)" (onSelect)="placeSelect($event)" [minLength]="1" field="name"
              [style]="{'width': '100%'}"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="flex flex-column lg:flex-row">
        <div class="flex col">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-sort-numeric-up"></i></span>
            <input type="text" pInputText [(ngModel)]="createEditLocation.externalLocationReference"
              placeholder="Location Code" formControlName="externalLocationReferenceInput" />
          </div>
        </div>
        <div class="flex col">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fa-solid fa-cubes-stacked"></i></span>
            <input type="text" pInputText [(ngModel)]="createEditLocation.name" placeholder="Location Name"
              formControlName="nameInput" />
          </div>
        </div>
      </div>
      <div class="flex flex-column lg:flex-row">
        <div class="flex col">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-sliders-v"></i></span>
            <p-dropdown id="company" [options]="companyList" [(ngModel)]="createEditLocation.company" optionLabel="name"
              placeholder="Parent Company" [autoDisplayFirst]="false" [filter]="true" filterBy="name" [showClear]="true"
              formControlName="companyDropdown" [style]="{'width': '100%'}">
              <ng-template pTemplate="filter" let-options="options">
                <div class="flex p-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                    <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                  </div>
                  <button pButton icon="pi pi-times" class="ml-3"></button>
                </div>
              </ng-template>
              <ng-template let-company pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img src="{{ company.image }}" style="height: 28px" />
                  <div>{{ company.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="flex col">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-th-large"></i></span>
            <p-dropdown id="location-type" [options]="locationTypes" [(ngModel)]="createEditLocation.locationType"
              optionLabel="name" optionValue="value" placeholder="Location Type" [autoDisplayFirst]="false"
              formControlName="locationTypeDropdown" [style]="{'width': '100%'}">
              <ng-template let-locationType pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ locationType.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

      </div>
      <div class="flex flex-column lg:flex-row">
        <div class="flex col">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fa-solid fa-square-phone"></i></span>
            <p-autoComplete formControlName="searchDefaultContact"
              [ngClass]="{'p-invalid': ((createEditLocationForm.get('search')?.touched || createEditLocationForm.get('search')?.dirty) )}"
              [autofocus]="true" [forceSelection]="true" placeholder="Default Contact [+966XXXXXXXXX]" autocorrect="off"
              autocapitalize="off" spellcheck="off" [suggestions]="searchContactResults"
              (completeMethod)="searchContacts($event)" (onSelect)="contactSelect($event)" [minLength]="1" field="name"
              [style]="{'width': '100%'}" [type]="'search'">
              <ng-template let-customer pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <img src="{{ customer.imageUrl }}" style="width: 18px" />
                  <div>{{ customer.name }} ({{ customer.company?.name }})</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

      </div>

      <div class="flex flex-column lg:flex-row align-items-end">
        <div class="flex col">
          <p-button label="{{ createEditLocation.id ? 'Update' : 'Create' }}" icon="fa-solid fa-plus" iconPos="left"
            [loading]="locationCreationInProgress" class="m-1 px-1 py-1" (onClick)="createUpdateLocation()"></p-button>
          <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
            class="m-1 px-1 py-1 p-button-help"></p-button>
        </div>

      </div>
    </div>

  </form>
</div>

<div id="map"></div>

<p-dialog header="Header" [(visible)]="showCustomerCreateDialog" [modal]="true" [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Quick Register Contact</span>
  </ng-template>
  <form class="overFlow" [formGroup]="createContactForm" novalidate autocomplete="off" autocorrect="off"
    autocapitalize="off" spellcheck="off">
    <div class="flex flex-column lg:flex-row">
      <div class="flex col">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="fa-solid fa-building"></i></span>
          <p-dropdown id="contact-client" [options]="companyList" [(ngModel)]="newContact.company" [filter]="true"
            filterBy="name" [showClear]="true" optionLabel="name" placeholder="Client"
            formControlName="clientSelectDropdown">
            <ng-template let-company pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img src="{{ company.logo }}" style="height: 28px" />
                <div>{{ company.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="flex flex-column lg:flex-row">
      <div class="flex col">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="fa-solid fa-person"></i></span>
          <input type="text" pInputText [(ngModel)]="newContact.name" placeholder="Contact Name"
            formControlName="contactNameInput" />
        </div>
      </div>
    </div>
    <div class="flex flex-column lg:flex-row">
      <div class="flex col">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="fa-solid fa-mobile-screen"></i></span>
          <input type="text" pInputText [(ngModel)]="newContact.phoneNumber" placeholder="Mobile Number [+966XXXXXXXXX]"
            formControlName="contactPhoneInput" />
        </div>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="registerContact()" label="Register Contact"
      [loading]="customerCreationInProgress"></p-button>
  </ng-template>
</p-dialog>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { VehicleService } from '../../vehicle/vehicle.service';
import { Vehicle } from '../../vehicle/vehicle.model';
import { Driver } from '../driver.model';
import { DriverService } from '../driver.service';
import {SessionStorageService} from "../../../services/session-storage.service";
import {Country} from "../../../models/common/country";
import {FileSelectEvent, FileUploadEvent, FileUploadHandlerEvent} from "primeng/fileupload";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../../../environments/environment";

@Component({
    selector: 'app-edit-driver',
    templateUrl: './edit-driver.component.html',
    styleUrls: ['./edit-driver.component.css']
})
export class EditDriverComponent implements OnInit {

    createEditDriverForm: FormGroup<any>;
    nameInput: FormControl;
    phoneNumberInput: FormControl;
    emailAddressInput: FormControl;
    nationalityDropdown: FormControl;
    residingCountryDropdown: FormControl;
    preferredAddressInput: FormControl;
    residenceNumberInput: FormControl;
    residenceExpiryDateInput: FormControl;
    licenseNumberInput: FormControl;
    licenseExpiryDateInput: FormControl;
    currentVehicleDropdown: FormControl;

    driverId: number;
    isLoading: boolean = false;
    driver: Driver = new Driver();
    countries: Country[] = [];
    selectedCountry: Country | undefined;
    selectedResidingCountry: Country | undefined;
    availableVehicles: Vehicle[] = [];
    selectedVehicle: Vehicle | undefined;
    selectedResidenceExpiryDate: Date | undefined;
    selectedLicenseExpiryDate: Date | undefined;
    driverCreationInProgress: boolean = false;
    pdfUploadProgress: number = 0

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(
        private sessionStorageService: SessionStorageService,
        private route: ActivatedRoute,
        private driverService: DriverService,
        private messageService: MessageService,
        private vehicleService: VehicleService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.createEditDriverForm = new FormGroup({
            nameInput: new FormControl('', Validators.required),
            phoneNumberInput: new FormControl('', Validators.required),
            emailAddressInput: new FormControl(''),
            nationalityDropdown: new FormControl('', Validators.required),
            residingCountryDropdown: new FormControl('', Validators.required),
            preferredAddressInput: new FormControl('', Validators.required),
            residenceNumberInput: new FormControl('', Validators.required),
            residenceExpiryDateInput: new FormControl('', Validators.required),
            licenseNumberInput: new FormControl('', Validators.required),
            licenseExpiryDateInput: new FormControl('', Validators.required),
            currentVehicleDropdown: new FormControl('', Validators.required)
        });

        // Get driver ID from route parameters
        const driverId = this.route.snapshot.paramMap.get('id');

        if (driverId) {
            this.driverId = Number(driverId);
            this.loadDriver(); // Load driver if ID exists
        } else {
            this.initializeForNewDriver(); // Initialize for new driver
        }

        this.loadCountries();
        this.loadVehicles();

        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' },{ label: 'Drivers', routerLink: '/fleet/drivers' }, { label: driverId ? 'Update' : 'Create' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    initializeForNewDriver() {
        // Initialize for a new driver (if needed)
        this.driver = new Driver();
    }

    loadDriver() {
        this.isLoading = true;
        this.driverService.findDriverByFilter(undefined, this.driverId, undefined, undefined, true, 'en-US').subscribe(driverWrapper => {
            console.log(driverWrapper);
            this.driver = driverWrapper.data;
            this.selectedResidenceExpiryDate = new Date(this.driver.residenceExpiryDate);
            this.selectedLicenseExpiryDate = new Date(this.driver.licenseExpiryDate);
            this.updateFormValues();
            this.isLoading = false;
        });
    }

    updateFormValues() {
        this.createEditDriverForm.patchValue({
            nameInput: this.driver.name,
            phoneNumberInput: this.driver.phoneNumber,
            emailAddressInput: this.driver.emailAddress,
            nationalityDropdown: this.driver.nationality,
            residingCountryDropdown: this.driver.residingCountry,
            preferredAddressInput: this.driver.preferredAddress,
            residenceNumberInput: this.driver.residenceNumber,
            residenceExpiryDateInput: this.selectedResidenceExpiryDate,
            licenseNumberInput: this.driver.licenseNumber,
            licenseExpiryDateInput: this.selectedLicenseExpiryDate,
            currentVehicleDropdown: this.driver.currentVehicle
        });

        this.selectedCountry = this.countries.find(c => c.id === this.driver.nationality?.id);
        this.selectedResidingCountry = this.countries.find(c => c.id === this.driver.residingCountry?.id);
        this.selectedVehicle = this.availableVehicles.find(v => v.vehicleId === this.driver.currentVehicle?.vehicleId);
    }

    loadCountries() {
        this.countries = this.sessionStorageService.getCountryList() || [];
    }

    loadVehicles() {
        const companyId = this.sessionStorageService.getCompany()?.id;
        if (companyId) {
            this.vehicleService.findVehiclesByCompanyId(0, 10, companyId)
                .subscribe(response => {
                    this.availableVehicles = response.items;
                });
        }
    }

    onCountryChange(event: any) {
        this.selectedCountry = event.value;
        if (this.selectedCountry)
            this.driver.nationality = this.selectedCountry;
    }

    onResidingCountryChange(event: any) {
        this.selectedResidingCountry = event.value;
        if (this.selectedResidingCountry)
            this.driver.residingCountry = this.selectedResidingCountry;
    }

    onVehicleChange(event: any) {
        this.selectedVehicle = event.value;
        this.driver.currentVehicle = this.selectedVehicle;
    }

    saveDriver() {
        this.driverCreationInProgress = true;
        this.driver.residenceExpiryDate = this.selectedResidenceExpiryDate?.getTime() ?? new Date().getTime();
        this.driver.licenseExpiryDate = this.selectedLicenseExpiryDate?.getTime() ?? new Date().getTime();
        if (this.driver.id) {
            this.driverService.updateDriver(this.driver).subscribe(response => {
                if (response.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Driver ' + response.data.name + ' is updated',
                        detail: 'Driver ' + response.data.name + ' has been successfully updated'
                    });
                    this.router.navigate(['/fleet/drivers']);
                }
                this.driverCreationInProgress = false;
            });
        } else {
            this.driverService.addDriver(this.driver).subscribe(response => {
                if (response.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Driver ' + response.data.name + ' is created',
                        detail: 'Driver ' + response.data.name + ' has been successfully created'
                    });
                    this.router.navigate(['/fleet/drivers']);
                }
                this.driverCreationInProgress = false;
            });
        }
    }

    clearForm() {
        this.createEditDriverForm.reset();
        this.driver = new Driver();
        this.selectedCountry = undefined;
        this.selectedResidingCountry = undefined;
        this.selectedVehicle = undefined;
    }

    onResidenceCardUpload(event: FileSelectEvent) {
        console.log(event.files);
        this.uploadPDFFile(event.files[0], 'RESIDENCE');
    }

    onLicenseCardUpload(event: FileSelectEvent) {
        console.log(event.files);
        this.uploadPDFFile(event.files[0], 'LICENSE');
    }

    uploadPDFFile(file: File, fileType: string) {
        this.driverCreationInProgress = true;

        // Upload the PDF file to Firebase Storage
        const uploadTask = uploadBytesResumable(ref(storage, (fileType === 'RESIDENCE' ? '/residence_scan/' : '/license_scan/') + file.name), file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                this.pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.pdfUploadProgress = Math.round(this.pdfUploadProgress);
                if (this.pdfUploadProgress > 10)
                    this.pdfUploadProgress = this.pdfUploadProgress - 5;
                console.log('Upload is ' + this.pdfUploadProgress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file:", error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (fileType === 'RESIDENCE')
                        this.driver.residenceImage = downloadURL;
                    else this.driver.licenseImage = downloadURL;
                    this.driverCreationInProgress = false;
                }).catch((error) => {
                    console.error("Error getting download URL:", error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
                });
            }
        );
    }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {APIListWrapper} from "../models/common/api-list-wrapper.model";
import {ContractRevisions} from "./models/contract-revisions.model";
import {Contracts} from "./models/contracts.model";
import {DeliveryRateCard} from "./models/delivery-rate-card.model";
import {environment} from "../../environments/environment";



@Injectable({
    providedIn: 'root'
})
export class ContractsService {

    private baseUrl = `${environment.restApiUrl}contracts`; // Adjust the base URL if needed

    constructor(private http: HttpClient) { }

    // ---------- Contract CRUD Operations ----------

    getAllContracts(page: number, pageSize: number, firstPartyId?: number, secondPartyId?: number): Observable<APIListWrapper<Contracts>> {
        const reqParams = new HttpParams({
            fromObject: {
                page: page,
                pageSize: pageSize,
                firstPartyId: firstPartyId !== undefined ? firstPartyId : '',
                secondPartyId: secondPartyId !== undefined ? secondPartyId  : ''
            }
        });
        return this.http.get<APIListWrapper<Contracts>>(this.baseUrl, { params: reqParams });
    }

    getContractById(id: number): Observable<Contracts> {
        return this.http.get<Contracts>(`${this.baseUrl}/${id}`);
    }

    createContract(contract: Contracts): Observable<Contracts> {
        return this.http.post<Contracts>(this.baseUrl, contract);
    }

    updateContract(id: number, contract: Contracts): Observable<Contracts> {
        return this.http.put<Contracts>(`${this.baseUrl}/${id}`, contract);
    }

    deleteContract(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    // ---------- Contract Revision CRUD Operations ----------

    getAllRevisionsForContract(contractId: number): Observable<ContractRevisions[]> {
        return this.http.get<ContractRevisions[]>(`${this.baseUrl}/${contractId}/revisions`);
    }

    getRevisionById(contractId: number, revisionId: number): Observable<ContractRevisions> {
        return this.http.get<ContractRevisions>(`${this.baseUrl}/${contractId}/revisions/${revisionId}`);
    }

    createRevision(contractId: number, revision: ContractRevisions): Observable<ContractRevisions> {
        return this.http.post<ContractRevisions>(`${this.baseUrl}/${contractId}/revisions`, revision);
    }

    updateRevision(contractId: number, revisionId: number, revision: ContractRevisions): Observable<ContractRevisions> {
        return this.http.put<ContractRevisions>(`${this.baseUrl}/${contractId}/revisions/${revisionId}`, revision);
    }

    deleteRevision(contractId: number, revisionId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${contractId}/revisions/${revisionId}`);
    }

    // ---------- Delivery Rate Card Operations ----------

    getDeliveryRateCardsByRevisionId(contractId: number, revisionId: number): Observable<DeliveryRateCard[]> {
        return this.http.get<DeliveryRateCard[]>(`${this.baseUrl}/${contractId}/revisions/${revisionId}/delivery-rate-cards`);
    }

    createDeliveryRateCardsForRevision(contractId: number, revisionId: number, deliveryRateCards: DeliveryRateCard[]): Observable<DeliveryRateCard[]> {
        return this.http.post<DeliveryRateCard[]>(
            `${this.baseUrl}/${contractId}/revisions/${revisionId}/delivery-rate-cards`,
            deliveryRateCards
        );
    }

    getDeliveryRateCardByRateId(rateId: number): Observable<DeliveryRateCard> {
        const url = `${this.baseUrl}/delivery-rate-cards/${rateId}`;
        return this.http.get<DeliveryRateCard>(url);
    }
}

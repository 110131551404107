<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="drivers" dataKey="invoiceId" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
         [paginator]="true" [rows]="10" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRecords" [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
         [rowsPerPageOptions]="[10, 20, 50]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Drivers</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Driver" icon="pi pi-plus" (onClick)="addDriver()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Nationality</th>
            <th>Residing Country</th>
            <th>Preferred Address</th>
            <th>Iqama#</th>
            <th>License#</th>
            <th>Current Vehicle</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-driver>
        <tr>
            <td>
                <p-chip [label]="driver.name">
                    <img src="{{ driver.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                         alt="{{ driver.name }}"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                </p-chip>
            </td>
            <td>{{ driver.phoneNumber }}</td>
            <td>
                <p-chip [label]="driver.nationality?.name">
                    <img src="{{ driver.nationality?.flag }}"
                         alt="{{ driver.nationality?.name }} Flag"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                </p-chip>
            </td>
            <td>
                <p-chip [label]="driver.residingCountry?.name">
                    <img src="{{ driver.residingCountry?.flag }}"
                         alt="{{ driver.residingCountry?.name }} Flag"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                </p-chip>
            </td>
            <td>{{ driver.preferredAddress }}</td>
            <td>{{ driver.residenceNumber }}<br>{{ driver.residenceExpiryDate | date:'dd/MM/yyyy' }}</td>
            <td>{{ driver.licenseNumber }}<br>{{ driver.licenseExpiryDate | date:'dd/MM/yyyy' }}</td>
            <td>
                <p-chip [label]="driver.currentVehicle?.plateNumber"/>
            </td>
            <td>
                <button pButton type="button" icon="pi pi-pencil" (click)="editDriver(driver)"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deleteDriver(driver)"
                        class="p-button-rounded p-button-text p-button-danger"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRecords}} drivers.
        </div>
    </ng-template>
</p-table>

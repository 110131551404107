import { Routes } from '@angular/router';
import { OrderComponent } from './order.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { OrderCompanyComponent } from './company/order-company.component';

export const orderRoutes: Routes = [
    {
        path: 'order',
        children: [
            {
                path: '',
                component: OrderComponent,
            },
            {
                path: 'company',
                component: OrderCompanyComponent,
            },
            {
                path: 'create',
                component: CreateOrderComponent
            },
            {
                path: 'view/:orderNumber',
                component: ViewOrderComponent,
            },
            {
                path: 'delivery/view/:deliveryNumber', // Direct child of 'order' route
                component: DeliveryComponent
            },
        ]
    },
];

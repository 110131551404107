import { Routes } from '@angular/router';
import { LocationComponent } from './location.component';
import { CreateLocationComponent } from './create-location/create-location.component';

export const locationRoutes: Routes = [
    {
        path: 'location',
        children: [
            { path: '', component: LocationComponent, },
            { path: 'create', component: CreateLocationComponent }
        ]
    },
];

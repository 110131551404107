import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIResponseWrapper } from "../../models/common/api-response-wrapper";
import { Vehicle } from "../vehicle/vehicle.model";
import { Driver } from "./driver.model";
import { APIListWrapper } from "../../models/common/api-list-wrapper.model";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DriverService {

    private baseUrl = `${environment.restApiUrl}driver-endpoint`; // Adjust the base URL if needed

    constructor(private http: HttpClient) { }

    findDriverByFilter(driverId?: string, driverDatabaseId? : number, emailAddress?: string, phoneNumber?: string, vehicleInfoRequired?: boolean, cultureCode?: string) {
        const params: any = {}; // Start with an empty object

        // Conditionally add parameters if they have values
        if (driverId) {
            params.driverId = driverId;
        }
        if (driverDatabaseId) {
            params.driverDatabaseId = driverDatabaseId;
        }
        if (emailAddress) {
            params.emailAddress = emailAddress;
        }
        if (phoneNumber) {
            params.phoneNumber = phoneNumber;
        }
        if (vehicleInfoRequired !== undefined) {
            params.vehicleInfoRequired = vehicleInfoRequired.toString();
        }
        if (cultureCode) {
            params.cultureCode = cultureCode;
        }
        return this.http.get<APIResponseWrapper<Driver>>(`${this.baseUrl}/driver`, { params });
    }

    findVehiclesByDriver(driverId: string, cultureCode: string) {
        const params = { driverId, cultureCode };
        return this.http.get<Vehicle[]>(`${this.baseUrl}/vehicles`, { params });
    }

    addDriver(driver: Driver)  {
        return this.http.post<APIResponseWrapper<Driver>>(`${this.baseUrl}/driver`, driver);
    }

    updateDriver(driver: Driver) {
        return this.http.put<APIResponseWrapper<Driver>>(`${this.baseUrl}/driver`, driver);
    }

    updateCurrentVehicleForDriver(driverId: string, vehicleId: number) {
        const params = { driverId, vehicleId: vehicleId.toString() };
        return this.http.put<APIResponseWrapper<Driver>>(`${this.baseUrl}/driver-current-vehicle`, null, { params });
    }

    deleteDriver(driver: Driver) {
        return this.http.delete<APIResponseWrapper<Driver>>(`${this.baseUrl}/driver`, { body: driver });
    }

    findDriversByTransportationAgencyId(page: number, pageSize: number, transportationAgencyId: number) {
        const params = {
            page: page.toString(),
            pageSize: pageSize.toString(),
            transportationAgencyId: transportationAgencyId.toString()
        };
        return this.http.get<APIListWrapper<Driver>>(`${this.baseUrl}/drivers-by-agency`, { params });
    }
}
